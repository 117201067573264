import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../config/config";
import { Button } from "../misc/button/Button";
import { Input, Spin, Alert } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { NotificationContext } from "../context/NotificationWrapper";
import { AuthContext } from "../context/AuthProviderWrapper";
import SpinLoading from "../misc/loading/SpinLoading";

function Login() {
  const [userLogin, setUserLogin] = useState({
    username: null,
    password: null,
  });
  const { authenticateUser, user, isLoggedIn } = useContext(AuthContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const backendUrl = BACKEND_URL;
  const receivedDataIdentifier = state?.message;
  const [isLoading, setIsLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (user && isLoggedIn) {
      if (user.isAdmin === false && user?.companies?.length === 0) {
        navigate("/empty");
      } else if (user.isAdmin === false && user?.companies?.length >= 1) {
        navigate("/infoIdentifier");
      } else if (user.isAdmin === true) {
        navigate("/admin");
      }
    }
  }, [user, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const requestBody = {
      username: receivedDataIdentifier,
      password: userLogin?.password,
    };
    axios
      .post(`${backendUrl}/api/login`, requestBody, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(async () => {
        await authenticateUser();
      })
      .catch(({ response: e }) => {
        setIsLoading(false);
        setError(e.data.error);
        if (e.status !== 401) {
          openNotification(
            "Erreur",
            "Une erreur est survenue, veuillez réessayer",
            "error"
          );
        }
      });
  };

  const handleChange = (e) => {
    setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };

  const errorDisplay = (
    <Alert
      type="error"
      message={error}
      style={{ width: "100%", alignItems: "center", margin: "auto" }}
    />
  );

  return (
    <>
      <Spin spinning={isLoading} fullscreen data-testid="ant-spin" />
      <div className="login-container" data-testid="login-container">
        <div className="form-container">
          <div>{error && errorDisplay}</div>
          <form onSubmit={handleSubmit}>
            <div className="box-label-input">
              <label>Mot de passe</label>
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                name="password"
                value={userLogin?.password}
                onChange={handleChange}
                required
                style={{ outline: "none", border: "none", width: "auto" }}
              />
            </div>
            <Button
              type="submit"
              isDisabled={isLoading}
              children={isLoading ? <SpinLoading /> : "Envoyer"}
            />
          </form>
        </div>
        <div className="reset-password">
          <Link to="/reset-password">Mot de passe oublié</Link>
        </div>
      </div>
    </>
  );
}

export default Login;
