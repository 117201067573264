import React from "react";
import ErrorComponent from "../error/ErrorComponent";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: "", stack: "" },
    info: { componentStack: "" },
  };

  static getDerivedStateFromError(error) {
    return { hasErrors: true };
  }

  componentDidCatch(error, info) {
    console.log("catched", error, info);
    this.setState({ error, info });
  }

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorComponent /> : children;
    // if (this.state.hasErrors) {
    //     return this.props.fallback;
    // }
    // return this.props.children;
  }
}

export default ErrorBoundary;
