import React from "react";
import { Result } from "antd";
import { ButtonLink } from "../../../misc/button/Button";

function SuccessResultPayment() {
  return (
    <div className="block-right">
      <div className="info-result-payment">
        <Result
          status="success"
          title="Votre paiement a été enregistré avec succès."
        />
        <div className="button-container">
          <ButtonLink to="/tableDeclaration" children="Retour" />
        </div>
      </div>
    </div>
  );
}

export default SuccessResultPayment;
