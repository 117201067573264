import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProviderWrapper";

function CompaniesList({ handleSelectCompany }) {
  const { userCompanies } = useContext(AuthContext);

  return (
    <div className="companies-btn">
      {userCompanies?.map((elm) => {
        return (
          <button
            key={elm.companyStatus}
            onClick={() => handleSelectCompany(elm)}
          >
            {elm?.companyName}
          </button>
        );
      })}
    </div>
  );
}

export default CompaniesList;
