export const renderCheckboxOrHidden = (dataUser, salary) => {
  const conditionLaborUnion = dataUser?.laborUnion !== 2;

  const conditionSalary1 = salary?.workForce > 10;
  const conditionSalary2 = !salary && dataUser?.workForce > 10;
  const conditionSalary3 =
    (salary?.workForce > 10 || salary === null) &&
    dataUser?.workForce === "moreThan10";

  const condition =
    (conditionLaborUnion && conditionSalary1) ||
    (conditionLaborUnion && conditionSalary2) ||
    (conditionLaborUnion && conditionSalary3);

  return condition;
};
