import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../config/config";
import { Button } from "../misc/button/Button";
import { Checkbox, Form, Input, Radio, Alert, Spin, Modal, Select } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { NotificationContext } from "../context/NotificationWrapper";
import SpinLoading from "../misc/loading/SpinLoading";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const options = [
  { value: 2, label: "CAPEB" },
  { value: 3, label: "FFB" },
  { value: 1, label: "Aucun" },
];

function Signup() {
  const { openNotification } = useContext(NotificationContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const backendUrl = BACKEND_URL;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [pwPromptForm] = Form.useForm();
  const [workForce, setWorkForce] = useState("upTo10");
  const [visible, setVisible] = useState(true);
  const [isAgreeOptWorkHidden, setIsAgreeOptWorkHidden] = useState(true);
  const [pwPromptVisible, setPwPromptVisible] = useState(false);
  const [pwUser, setPwUser] = useState({ user: null, membershipCode: null });
  const [pwError, setPwError] = useState([])

  const msg = {
    newUserCreated: {
      text: (
        <div>
          Il ne reste plus qu'une seule seule étape pour terminer votre
          inscription :<br /> Nous vous avons evoyé un e-mail avec un lien à
          suivre, valable <b>24 heures</b>.<br />
          Si vous ne le trouvez pas, pensez à vérifier dans les spams.
        </div>
      ),
      title: "Finaliser l'inscription",
    },
    attachNewCompany: {
      text: (
        <div>
          L'adhérent a été rattaché avec succès sur votre compte.
          <br />
        </div>
      ),
      title: "Succès",
    },
  };

  const infoModal = (key) => {
    Modal.info({
      title: msg[key]?.title,
      content: msg[key]?.text,
      onOk() {
        navigate("/");
      },
    });
  };

  const onFinish = (values) => {
    setIsLoading(true);
    setError("");
    const requestBody = {
      email: values.email,
      plainPassword: values.password,
      representativeLastname: values.representativeLastname,
      representativeFirstname: values.representativeFirstname,
      representativeFunction: values.representativeFunction,
      representativePhone: values.representativePhone,
      laborUnion: values.laborUnion,
      workForce: values.workForce,
      membershipCode: state.message,
      buildingConventionOutside77: values.buildingConventionOutside77,
      agreeOptionalWorker: values.agreeOptionalWorker,
      agreeTermsServiceMember: values.agreeTermsServiceMember,
    };
    axios
      .post(`${backendUrl}/api/register`, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data: response }) => {
        setIsLoading(false);
        if (response.message === "need_password") {
          const { user, membershipCode } = response.data;
          setPwUser({
            user: user,
            membershipCode: membershipCode,
          });
          promptPassword(response.data.userId, response.data.membershipCode);
        } else {
          infoModal("newUserCreated");
        }
      })
      .catch((error) => {
        console.error("error", error);
        setIsLoading(false);
        openNotification("Erreur", error.response.data.data, "error");
      });
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleWorkForce = (e) => {
    setWorkForce(e.target.value);
    checkIfAgreeOptionalWorkerShouldBeHidden();
  };

  const handleLaborUnionChange = (e, i, a) => {
    checkIfAgreeOptionalWorkerShouldBeHidden();
  };

  const checkIfAgreeOptionalWorkerShouldBeHidden = () => {
    const wf = form.getFieldValue("workForce");
    const lu = form.getFieldValue("laborUnion");
    if (lu === 2) {
      setIsAgreeOptWorkHidden(true);
      return true;
    } else if (wf === "upTo10") {
      setIsAgreeOptWorkHidden(true);
      return true;
    } else {
      setIsAgreeOptWorkHidden(false);
      return false;
    }
  };

  const promptPassword = () => {
    setPwPromptVisible(true);
  };

  const onOKPromptPw = () => {
    setIsLoading(true);
    setPwError([]);
    const payload = {
      user: pwUser.user,
      membershipCode: pwUser.membershipCode,
      plainPassword: pwPromptForm.getFieldValue("password"),
    };
    axios
      .post(`${backendUrl}/api/register/verify/password`, payload, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data: response }) => {
        setPwPromptVisible(false);
        infoModal("attachNewCompany");
      })
      .catch((error) => {
        setPwError(error.response.data.data)
        console.error("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCancelPromptPw = () => {
    setPwPromptVisible(false);
    setIsLoading(false);
    setPwError([]);
  };

  // const onFinishPromptPw = (values) => {
  //   const { password } = values;
  //   console.log(password);
  //   //  TODO
  // };

  const errorDisplay = (
    <Alert
      type="error"
      message={error}
      style={{ width: "100%", alignItems: "center", margin: "auto" }}
    />
  );

  const pwErrorDisplay = (
    <Alert
      type="error"
      message={pwError?.length > 0 && pwError.map((el) => <p>{el}</p>)}
      style={{ marginBottom: 20 }}
    />
  );

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <Modal
        title="Première connexion de l'adhérent"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button
            key="ok"
            style={{
              color: "#fff",
              background: "#1677ff",
              borderRadius: "5px",
              fontSize: "15px",
              padding: "3px 10px",
            }}
            onClick={handleOk}
          >
            OK
          </button>,
        ]}
      >
        <p>
          Lors de la première connexion, nous vous invitons à vérifier
          l'ensemble des « informations adhérent » pré-remplies et à les
          modifier si elles ne correspondent pas à votre situation. Vous voudrez
          bien également compléter celles manquantes et procéder à une
          actualisation en cas d'évolution de la situation.
        </p>
      </Modal>
      <div className="signup-container">
        <h1
          style={{
            fontSize: "22px",
            marginTop: "30px",
            marginBottom: "30px",
            fontWeight: "800",
          }}
        >
          JE CRÉE UN COMPTE : {state?.companyName.toUpperCase()}
        </h1>
        {error && errorDisplay}
        <Form
          {...formItemLayout}
          form={form}
          name="registerUser"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            buildingConventionOutside77: true,
            workForce: "upTo10",
            agreeOptionalWorker: false,
          }}
          data-testid="registerUser"
        >
          <Form.Item
            name="email"
            label="E-mail professionnel"
            rules={[
              {
                type: "email",
                message: "Ce n'est pas une adresse e-mail valide",
              },
              {
                required: true,
                message: "Veuillez entrer votre e-mail",
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email2"
            label="Confirmer votre e-mail"
            dependencies={["email"]}
            hasFeedback
            rules={[
              {
                type: "email",
                message: "Ce n'est pas une adresse e-mail valide",
              },
              {
                required: true,
                message: "Veuillez confirmer votre e-mail",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("email") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Les deux e-mails ne correspondent pas")
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Mot de passe"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            required
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre nouveau mot de passe",
              },
              {
                validator(_, value) {
                  if (
                    /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])(?=\S*[\W])\S*$/.test(
                      value
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Votre mot de passe doit être long de 8 caractère ou plus, et doit contenir au moins 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial."
                    )
                  );
                },
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="password2"
            label="Confirmer votre mot de passe"
            dependencies={["password"]}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            required
            rules={[
              {
                required: true,
                message: "Veuillez confirmer votre nouveau mot de passe",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Les deux mots de passe ne correspondent pas")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="representativeLastname"
            label="Nom représentant légal"
            rules={[
              {
                required: true,
                message: "Veillez entrer le nom du représentant légal",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="representativeFirstname"
            label="Prénom représentant légal"
            rules={[
              {
                required: true,
                message: "Veillez entrer le prénom du représentant légal",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="representativeFunction"
            label="Fonction représentant légal"
            rules={[
              {
                required: true,
                message: "Veillez entrer la fonction du représentant légal",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="representativePhone"
            label="Téléphone représentant légal"
            rules={[
              {
                required: true,
                message:
                  "Veillez entrer le numéro de téléphone du représentant légal",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="laborUnion"
            label="Adhérent"
            rules={[
              {
                required: true,
                message: "Séléctionnez une option",
              },
            ]}
          >
            <Select options={options} onChange={handleLaborUnionChange} />
          </Form.Item>

          <Form.Item
            label="Effectifs"
            onChange={handleWorkForce}
            name="workForce"
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="upTo10">Jusqu'à 10</Radio.Button>
              <Radio.Button value="moreThan10">Plus de 10</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="agreeOptionalWorker"
            valuePropName="checked"
            hidden={isAgreeOptWorkHidden}
            rules={[
              {
                validator: (_, value) =>
                  (value && workForce === "moreThan10") ||
                  workForce === "upTo10" ||
                  form.getFieldValue("laborUnion") === 2
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Veuillez valider avoir pris connaissance")
                      ),
              },
            ]}
            {...tailFormItemLayout}
          >
            <Checkbox>
              J'ai pris connaissance que la souscription aux Prestations pour
              les Ouvriers est optionnelle
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="buildingConventionOutside77"
            valuePropName="checked"
            extra="Case à décocher si l'Adhérent est Volontaire ou situé dans le 77"
            {...tailFormItemLayout}
          >
            <Checkbox>Conventions du Bâtiment hors 77</Checkbox>
          </Form.Item>

          <Form.Item
            name="agreeTermsServiceMember"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "Vous devez accepter les conditions générales d'utilisation"
                        )
                      ),
              },
            ]}
            {...tailFormItemLayout}
          >
            <Checkbox>
              J'ai lu et accepte{" "}
              <Link
                to="/charte-adherent"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blue" }}
              >
                la charte de l'adhérent
              </Link>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="submit"
              children={isLoading ? <SpinLoading /> : "Valider"}
              style={{ marginRight: "10px", marginTop: "10px" }}
            />
          </Form.Item>
        </Form>
        <Modal
          title="Veuillez entrer votre mot de passe"
          open={pwPromptVisible}
          onCancel={onCancelPromptPw}
          footer={[
            <Button
              onClick={onOKPromptPw}
              children={isLoading ? <SpinLoading /> : "OK"}
              style={{
                marginRight: "20px",
              }}
            />,
          ]}
        >
        <div style={{ marginBottom: 20 }}>
          Pour ajouter cet Adhérent à votre compte, saisissez le mot de passe du compte :
        </div>
        {pwError.length > 0 && pwErrorDisplay}
          <Form
            {...formItemLayout}
            form={pwPromptForm}
            name="promptPassword"
            // onFinish={onFinishPromptPw}
            scrollToFirstError
            data-testid="registerUser"
          >
            <Form.Item
              name="password"
              label="Mot de passe"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              required
              rules={[
                {
                  required: true,
                  message: "Veuillez entrer votre mot de passe",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default Signup;
