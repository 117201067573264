import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthProviderWrapper";
import IconUser from "./IconUser";
import { useMediaQuery } from "react-responsive";
import ModalIconUser from "./ModalIconUser";
import SpinLoading from "../../misc/loading/SpinLoading";

function AccountMenu({ handleShow, showModal, handleLogout }) {
  const isSmallScreen = useMediaQuery({ maxWidth: 990 });
  const { user, isLoggedIn, activeCompany, isAdmin, isLoading, userCompanies } =
    useContext(AuthContext);

  return (
    <div className="block-userlogin">
      <Link
        className="block-title icon-user"
        onClick={isLoggedIn ? handleShow : ""}
      >
        <IconUser />
        <div className="title">
          {!isLoggedIn ? (
            <>
              <p>Mon espace APAS-BTP</p>
              <p style={{ fontWeight: 600 }}>
                <Link className="icon-user" to="/">
                  Connexion
                </Link>
              </p>
            </>
          ) : (
            <>
              {!isAdmin ? (
                <>
                  {isLoading ? (
                    <SpinLoading />
                  ) : (
                    <>
                      <p>
                        {activeCompany?.representativeFirstname}{" "}
                        {activeCompany?.representativeLastname}
                      </p>
                      <p style={{ fontWeight: 600 }}>
                        {
                          userCompanies.length > 0 ?
                          activeCompany?.companyName :
                          user.email
                        }
                      </p>
                    </>
                  )}
                </>
              ) : (
                <p> {user?.email}</p>
              )}

              {!isSmallScreen && showModal && (
                <div className="modal-auth modal-auth-desktop">
                  <ModalIconUser handleLogout={handleLogout} />
                </div>
              )}
            </>
          )}
        </div>
      </Link>
    </div>
  );
}

export default AccountMenu;
