import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import { Space, AutoComplete } from "antd";

export default function SearchAutocomplete(props) {
  const { onValidate, entity, defaultValue, style, name, handleChange, value } =
    props;
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  // whenever value change, we send a new post request
  useEffect(() => {
    if (value?.toString()?.length > 0) {
      setOpen(true);
      switch (entity) {
        case "user":
          fetchUserAutocomplete();
          break;
        default:
          break;
      }
    } else {
      setOpen(false);
      onValidate(null); // to reset data
    }
  }, [value]);

  useEffect(() => {
    const keyUpHandler = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleOnEnter();
      }
    };
    // Every time the user presses and releases a key
    document.addEventListener("keyup", keyUpHandler);
    return () => {
      document.removeEventListener("keyup", keyUpHandler);
    };
  });

  const handleOnEnter = () => {
    if (value?.length === 0) {
      onValidate(null);
    } else {
      if (options[0]?.hasOwnProperty("label")) {
        handleChange(options[0].label);
        onValidate(options[0].value);
      }
    }
  };

  const fetchUserAutocomplete = () => {
    axios
      .post(
        `${BACKEND_URL}/api/user/search`,
        {
          search: value,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        formatAndSetResults(res.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const formatAndSetResults = (result) => {
    // nextOptions : results list about search
    const nextOptions = result.map((el, index) => {
      return { label: el.label, value: el.id, type: el.type, key: index };
    });
    setOptions(nextOptions);
  };

  // we use this function to display the right value inside the text input when we click on an option.
  const handleSelect = (e) => {
    // console.log("e", e); // c_11
    const element = options.filter((option) => option.value === e);
    handleChange(element[0].label);
    onValidate(element[0].value);
    setOpen(false);
    return element[0];
  };

  return (
    <Space wrap>
      <AutoComplete
        placeholder="Chercher une entreprise"
        name={name}
        defaultValue={defaultValue ? defaultValue : ""}
        value={value}
        onChange={(event) => handleChange(event)}
        onSelect={(event) => onValidate(handleSelect(event))} // id
        style={style}
        options={options}
        backfill={true}
        open={open}
      />
    </Space>
  );
}
