import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CompaniesList from "../../right-contentNavigation/multiAccount/CompaniesList";
import { AuthContext } from "../../context/AuthProviderWrapper";
import { Spin } from "antd";

function ModalIconUser({ handleLogout }) {
  const { user, userCompanies, changeActiveCompany, isLoading, setIsLoading } =
    useContext(AuthContext);
  // const [isLoading, setIsLoading] = useState(false);

  const handleSelectCompany = async (selectedCompany) => {
    setIsLoading(true);
    changeActiveCompany(selectedCompany);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoading(false);
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <Link to="/" onClick={handleLogout}>
        Déconnexion
      </Link>
      {user?.companies?.length > 0 && (
        <>
          <Link to="/change-email"> Modifier Email</Link>
          <Link to="/change-password"> Modifier Mot de passe</Link>
        </>
      )}
      {userCompanies?.length > 1 && (
        <CompaniesList handleSelectCompany={handleSelectCompany} />
      )}
    </>
  );
}

export default ModalIconUser;
