import React from "react";

function InfoRow(props) {
  const { left, right, idToTest, nameInput } = props;
  return (
    <div className="info-row-container">
      <label htmlFor={nameInput} className="info-row-left">
        {left}
      </label>
      <div className="info-row-right" data-testid={idToTest}>
        {right}
      </div>
    </div>
  );
}

export default InfoRow;
