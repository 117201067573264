import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { AuthContext } from "../context/AuthProviderWrapper";

function Logo() {
  const { user, isLoggedIn, isAdmin } = useContext(AuthContext);

  let destination;
  if (isLoggedIn && user?.companies?.length >= 1) {
    destination = "/infoIdentifier";
  } else if (isLoggedIn && user?.companies?.length === 0 && !isAdmin) {
    destination = "/empty";
  } else if (!isLoggedIn) {
    destination = "/";
  } else if (isAdmin) {
    destination = "/admin";
  }
  return (
    <>
      <Link to={destination}>
        <img src={logo} alt="Logo_APAS" />
      </Link>
    </>
  );
}

export default Logo;
