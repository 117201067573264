import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button } from "../../misc/button/Button";
import { AuthContext } from "../../context/AuthProviderWrapper";
import { DeclarationContext } from "../../context/DeclarationWrapper";
import { BACKEND_URL } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import EditDeclarationModal from "../declarationDetail-CRUD/EditDeclarationModal";

function DeclarationSignature() {
  const { declaration, updateDeclaration } = useContext(DeclarationContext);
  const { user, activeCompany } = useContext(AuthContext);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isEditableFlag, setIsEditableFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    updateDeclaration((prev) => ({
      ...prev,
      membershipCode: activeCompany.membershipCode,
    }));
  }, [activeCompany]);

  const abortControllerRef = useRef(null);

  const checkIfEditable = (declaration) => {
    if (!declaration?.id || (declaration?.payed === "no" && user.isAdmin)) {
      return true;
    }
    return false;
  };

  const handleEdit = (record) => {
    const flag = checkIfEditable(record);
    setIsEditableFlag(flag);
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setIsEditableFlag(false);
  };

  const getPdf = (id) => {
    axios
      .get(`${BACKEND_URL}/api/declaration/create-bill/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const handleCreate = () => {
    setIsLoading(true);
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    setError({});
    const requestBody = {
      salaryWorker: declaration?.salaryWorker,
      salaryEtam: declaration?.salaryEtam,
      salaryExecutive: declaration?.salaryExecutive,
      workForce: declaration?.workForce,
      month: declaration?.month,
      year: declaration?.year,
      agreeOptionalWorker: declaration?.agreeOptionalWorker,
      membershipCode: activeCompany.membershipCode,
    };

    axios
      .post(`${BACKEND_URL}/api/declaration/create`, requestBody, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        signal: abortControllerRef.current?.signal,
      })
      .then((res) => {
        return res.data.data;
      })
      .then((res) => {
        getPdf(res.id);
        return res;
      })
      .then((res) => {
        navigate("/declaration-certificate-payment", {
          state: { message: res },
        });
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        Array.isArray(error.response.data?.data)
          ? setError(error.response.data.data)
          : setError([error.response.data.data]);
        console.error("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="block-right">
      <div className="info-declaration">
        <h4 style={{ textTransform: "uppercase" }}>Déclaration de salaires </h4>
        <div>
          <div>
            Période :{" "}
            <b>
              {declaration?.month}/{declaration?.year}
            </b>
          </div>
          <div>
            Montant dû : <b>{declaration?.feeAmountLocale} </b>
          </div>
        </div>
      </div>
      <div className="info-signature">
        <h4 style={{ textTransform: "uppercase" }}>
          Signer et envoyer le document{" "}
        </h4>
        <p
          style={{ fontStyle: "italic", fontWeight: "700", marginTop: "20px" }}
        >
          A ce stade, vous pouvez modifier les données saisies préalablement en
          cliquant sur le bouton « Modifier » ou valider les données en signant
          électroniquement ce document. En cliquant sur « Signer et Envoyer »,
          vous transmettez le document.
          <br />
          <br />
          Une facture vous sera transmise en ligne aussitôt après la
          vérification de la signature. Sans accusé de réception, la présente
          procédure ne peut être considérée comme achevée.
        </p>
        <div className="button-container">
          <Button children="Modifier" onClick={() => handleEdit(declaration)} />
          <Button onClick={handleCreate} children="Signer et envoyer" />

          <EditDeclarationModal
            open={editModalVisible}
            onCancel={handleEditModalCancel}
            record={declaration}
            mode={isEditableFlag ? "edit" : "view"}
            navigateTo="/declaration-summary"
          />
        </div>
      </div>
    </div>
  );
}

export default DeclarationSignature;
