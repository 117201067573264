import React from "react";

export default function NoMatch() {

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    }
    return (<div style={style}>
        <h1>La page demandée n'existe pas</h1>
    </div>);
}