import React from "react";

function UserZoneTitle() {
  return (
    <div className="user-zone-title">
      <p>ESPACE ADHÉRENT</p>
    </div>
  );
}

export default UserZoneTitle;
