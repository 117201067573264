import React from "react";
import { Result } from "antd";
import { ButtonLink } from "../../../misc/button/Button";
import { useLocation } from "react-router-dom";

function FailureResultPayment() {
  const { state } = useLocation();
  const declaration = state?.message;

  return (
    <div className="block-right">
      <div className="info-result-payment">
        <Result status="error" title="Le paiement a échoué." />
        <div className="button-container">
          <ButtonLink to="/tableDeclaration" children="Retour" />
          <ButtonLink
            to="/declaration-certificate-payment"
            children="Réessayer"
            state={{ message: declaration }}
          />
        </div>
      </div>
    </div>
  );
}

export default FailureResultPayment;
