import { Input, Modal, Form, Select, Checkbox } from "antd";
import { useEffect } from "react";
import { Button } from "../../misc/button/Button";

const EditUserModal = (props) => {
  const { open, onCancel, onEdit, record, laborUnionOption } = props;

  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(record);
  }, [form, record]);

  const handleSave = (data) => {
    form
      .validateFields()
      .then((values) => {
        const formData = {
          ...values,
          id: record.id,
          membershipCode: record.membershipCode,
        };
        onEdit(formData);
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  return (
    <Modal
      forceRender
      title="Modification d'utilisateur"
      open={open}
      closable={false}
      footer={
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            gap: "30px",
            justifyContent: "end",
          }}
        >
          <Button onClick={onCancel} children="Annuler" />
          <Button onClick={handleSave} children="Enregistrer" />
        </div>
      }
    >
      <Form className="edit-user-modal" form={form} initialValues={record}>
        <Form.Item name="companyStatus" label="Statut de l'entreprise">
          <Input />
        </Form.Item>
        <Form.Item name="naf" label="NAF">
          <Input />
        </Form.Item>
        <Form.Item name="companyName" label="Raison Sociale">
          <Input />
        </Form.Item>
        <Form.Item name="address1" label="Adresse 1">
          <Input />
        </Form.Item>
        <Form.Item name="address2" label="Adresse 2">
          <Input />
        </Form.Item>
        <Form.Item name="address3" label="Adresse 3">
          <Input />
        </Form.Item>
        <Form.Item name="address4" label="Adresse 4">
          <Input />
        </Form.Item>
        <Form.Item name="zipcode" label="Code postal">
          <Input />
        </Form.Item>
        <Form.Item name="city" label="Commune">
          <Input />
        </Form.Item>
        <Form.Item name="representativeFirstname" label="Prénom">
          <Input />
        </Form.Item>
        <Form.Item name="representativeLastname" label="Nom">
          <Input />
        </Form.Item>
        <Form.Item name="representativeFunction" label="Fonction">
          <Input />
        </Form.Item>
        <Form.Item name="representativePhone" label="Téléphone">
          <Input />
        </Form.Item>
        <Form.Item name="workForce" label="Effectifs" className="workForce">
          <Select style={{ width: 120 }}>
            <Option value="upTo10">Jusqu'à 10</Option>
            <Option value="moreThan10">Plus de 10</Option>
          </Select>
        </Form.Item>
        <Form.Item name="laborUnion" label="Adhérent" className="laborUnion">
          <Select style={{ width: 120 }}>
            {laborUnionOption.map((el, index) => (
              <Option key={index} value={el.value}>
                {el.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="buildingConventionOutside77"
          label="Convention Bâtiment hors 77"
          valuePropName="checked"
          className="checkbox"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
