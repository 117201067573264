import axios from "axios";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../config/config";
import { Button } from "../misc/button/Button";
import { Alert, Spin } from "antd";
import { NotificationContext } from "../context/NotificationWrapper";
import SpinLoading from "../misc/loading/SpinLoading";

function Connexion() {
  const [identifier, setIdentifier] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const backendUrl = BACKEND_URL;
  const { openNotification } = useContext(NotificationContext);

  const handleChange = (e) => {
    setIdentifier(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const requestBody = {
      membershipCode: identifier,
    };
    axios
      .post(`${backendUrl}/api/login/identifier`, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data: response }) => {
        setIsLoading(false);
        // if no user was found
        if (response.message === "error") {
          setError(response.data[0]);
        } else {
          let isRegistered = response?.data?.isRegistered;
          if (isRegistered === true) {
            navigate("/login", { state: { message: identifier } });
          } else if (isRegistered === false && /^\d+$/.test(identifier)) {
            navigate("/signup", {
              state: {
                message: identifier,
                companyName: response?.data?.companyName,
              },
            });
          } else {
            openNotification(
              "Erreur",
              "Une erreur est survenue, veuillez réessayer",
              "error"
            );
            navigate("/");
          }
        }
      })
      .catch((error) => {
        console.error("error", error);
        setIsLoading(false);
        openNotification(
          "Erreur",
          "Une erreur est survenue, veuillez réessayer ",
          "error"
        );
      });
  };

  const infoAlertContent = (
    <div>
      <h6>Comment trouver votre code adhérent ?</h6>
      <p>
        Votre code adhérent est présent sur le courrier de bienvenue qui vous a
        été envoyé.
        <br />
        Ce code est indispensable pour réaliser les déclarations de salaires.
        <br />
        Si vous ne le trouvez pas, nous vous invitons à contacter le service
        adhésions de l'APAS-BTP.
        <br /> Tel : <b>01 84 990 990</b>
        <br />
        Email :{" "}
        <b>
          <a href="mailto:adhesion@apas.asso.fr">adhesion@apas.asso.fr</a>
        </b>
      </p>
    </div>
  );

  const errorDisplay = (
    <Alert
      type="error"
      message={error}
      style={{ width: "100%", alignItems: "center", margin: "auto" }}
    />
  );

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="connexion-container">
        <div className="form-container">
          <div>{error && errorDisplay}</div>
          <form onSubmit={handleSubmit}>
            <div className="box-label-input">
              <label>Code adhérent ou email</label>
              <input
                type="text"
                name="identifier"
                value={identifier}
                onChange={handleChange}
                required
                data-testid="input-identifier"
              />
            </div>

            <Button
              type="submit"
              isDisabled={isLoading}
              children={isLoading ? <SpinLoading /> : "Envoyer"}
            />
          </form>
        </div>
        <Alert
          message={infoAlertContent}
          type="info"
          // closable
          style={{ margin: "10px" }}
        />
      </div>
    </>
  );
}

export default Connexion;
