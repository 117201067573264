import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

const styleButton = {
  zIndex: 1,
  display: "inline-block",
  position: "absolute",
  right: "-21px",
  top: 0,
  bottom: 0,
  width: "22px",
  verticalAlign: "middle",
  overflow: "hidden",
  fill: " #ee463b",
};

const styleSvg = {
  display: "inline-block",
  position: "absolute",
  top: 0,
  left: 0,
};

const styleText = {
  fontFamily: "Quicksand",
  textTransform: "uppercase",
  cursor: "pointer",
};

export function Button(props) {
  const {
    type,
    title,
    style,
    children,
    onClick,
    isDisabled,
    classNameElement,
  } = props;

  const btn = (
    <Tooltip title={title}>
      <button
        style={{ ...styleText, ...style }}
        type={type ? type : "button"}
        disabled={isDisabled ? isDisabled : false}
        className={classNameElement}
      >
        {children}
        <span className="svg-container" style={styleButton}>
          <svg
            version="1.1"
            height="100%"
            width="100%"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={styleSvg}
          >
            <path d="M 5,100 H 0 V 0 H 100 Z"></path>
          </svg>
        </span>
      </button>
    </Tooltip>
  );

  const btnWithClick = (
    <Tooltip title={title}>
      <button
        style={{ ...styleText, ...style }}
        type={type ? type : "button"}
        disabled={isDisabled ? isDisabled : false}
        className={classNameElement}
        onClick={onClick}
      >
        {children}
        <span className="svg-container" style={styleButton}>
          <svg
            version="1.1"
            height="100%"
            width="100%"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={styleSvg}
          >
            <path d="M 5,100 H 0 V 0 H 100 Z"></path>
          </svg>
        </span>
      </button>
    </Tooltip>
  );
  return props?.onClick ? btnWithClick : btn;
}

export function ButtonLink(props) {
  const {
    to,
    type,
    title,
    classNameElement,
    style,
    children,
    isDisabled,
    state,
    onClick,
  } = props;
  // const classNameElement = props.className ? `svg-btn ${props.className}` : 'svg-btn';
  return (
    <Tooltip title={title}>
      <button
        style={{ ...styleText, ...style }}
        type={type ? type : "button"}
        disabled={isDisabled ? isDisabled : false}
        className={classNameElement}
        onClick={onClick}
      >
        <Link style={{ color: "white" }} to={to} state={state}>
          {children}
          <span className="svg-container" style={styleButton}>
            <svg
              version="1.1"
              height="100%"
              width="100%"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={styleSvg}
            >
              <path d="M 5,100 H 0 V 0 H 100 Z"></path>
            </svg>
          </span>
        </Link>
      </button>
    </Tooltip>
  );
}
