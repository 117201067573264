import axios from "axios";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../../config/config";
import { Button } from "../../misc/button/Button";
import { NotificationContext } from "../../context/NotificationWrapper";
import { Form, Input, Spin } from "antd";
import SpinLoading from "../../misc/loading/SpinLoading";

function ResetPassword(props) {
  const backendUrl = BACKEND_URL;
  const navigate = useNavigate();
  const { openNotification } = useContext(NotificationContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    setIsLoading(true);
    axios
      .post(`${backendUrl}/api/reset-password`, values, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setIsLoading(false);
        openNotification(
          "Succès",
          "Un e-mail de réinitialisation vous a été envoyé.",
          "success"
        );
        navigate("/");
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification("Erreur", error.response.data.data, "error");
        console.error("error", error);
      });
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="reset-password-container">
        <h2>Réinitialisation du mot de passe</h2>
        <p>
          Entrez votre adresse e-mail et nous vous enverrons un lien pour
          réinitialiser votre mot de passe.
        </p>
        <Form
          form={form}
          name="sendMailResetPassword"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "Ce n'est pas une adresse e-mail valide",
              },
              {
                required: true,
                message: "Veuillez entrer votre e-mail",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="submit"
              children={isLoading ? <SpinLoading /> : "Envoyer"}
            />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default ResetPassword;
