import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import { Button, ButtonLink } from "../../misc/button/Button";
import { Form, Input, Spin } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { NotificationContext } from "../../context/NotificationWrapper";
import SpinLoading from "../../misc/loading/SpinLoading";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const styles = {
  blocInput: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: "20px",
    // outline: 'none',
    // border: 'none',
  },
  blocButton: {
    width: "250px",
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
};

function ChangePassword() {
  const navigate = useNavigate();
  const { openNotification } = useContext(NotificationContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    setIsLoading(true);
    const requestBody = {
      plainPassword: values.password,
      nextPlainPassword: values.pw1,
    };
    axios
      .post(`${BACKEND_URL}/api/register/change/password`, requestBody, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        openNotification("Succès", "Mot de passe changé.", "success");
        navigate("/infoIdentifier");
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification("Erreur", error.response.data.data, "error");
        console.error("error", error);
      });
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="info-detail update-page">
        <h2>Modification du mot de passe</h2>
        <div className="form-update-page">
          <Form
            {...formItemLayout}
            form={form}
            name="changePassword"
            onFinish={onFinish}
            style={styles.form}
            scrollToFirstError
          >
            <Form.Item
              name="password"
              label="Mot de passe actuel"
              style={styles.input}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              required
              rules={[
                {
                  required: true,
                  message: "Veuillez entrer votre mot de passe actuel",
                },
                {
                  validator(_, value) {
                    if (
                      /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])(?=\S*[\W])\S*$/.test(
                        value
                      )
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Votre mot de passe doit être long de 8 caractère ou plus, et doit contenir au moins 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial."
                      )
                    );
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="pw1"
              label="Nouveau mot de passe"
              style={styles.input}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              required
              rules={[
                {
                  required: true,
                  message: "Veuillez entrer votre nouveau mot de passe",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="pw2"
              label="Confirmez le mot de passe"
              style={styles.input}
              dependencies={["pw1"]}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              required
              rules={[
                {
                  required: true,
                  message: "Veuillez confirmer votre nouveau mot de passe",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("pw1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Les deux mots de passe ne correspondent pas")
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <div style={styles.blocButton}>
              <div>
                <ButtonLink to="/infoIdentifier" text="Retour">
                  Retour
                </ButtonLink>
              </div>
              <Form.Item>
                <Button
                  type="submit"
                  children={isLoading ? <SpinLoading /> : "Valider"}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
