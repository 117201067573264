import React, { useContext, useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import CompaniesList from "./CompaniesList";
import { AuthContext } from "../../context/AuthProviderWrapper";

function ModalSelectionCompany() {
  const {
    openModalChooseCompany,
    setOpenModalChooseCompany,
    changeActiveCompany,
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectCompany = async (selectedCompany) => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    changeActiveCompany(selectedCompany);
    setOpenModalChooseCompany(false);
    setIsLoading(false);
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <Modal
        open={openModalChooseCompany}
        closable={false}
        footer={null}
        className="modal-choose-company"
        width={400}
      >
        <div className="modal-content">
          <p>Choisir un adhérent</p>
          <CompaniesList handleSelectCompany={handleSelectCompany} />
        </div>
      </Modal>
    </>
  );
}

export default ModalSelectionCompany;
