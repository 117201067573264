import React from 'react';
import { notification } from 'antd';
const NotificationContext = React.createContext({
  name: 'Default',
});
const NotificationWrapper = (props) => {
  const [api, contextHolder] = notification.useNotification();
 
  const openNotification = (message, description, level) => {
    if (level === 'success') {
        api.success({
            message: `${message}`,
            description: description,
            placement: 'top',
          });
    } else if (level === 'info') {
        api.info({
            message: `${message}`,
            description: description,
            placement: 'top',
          });
    } else if (level === 'warning') {
        api.warning({
            message: `${message}`,
            description: description,
            placement: 'top',
          });
    } else if (level === 'error') {
        api.error({
            message: `${message}`,
            description: description,
            placement: 'top',
          });
    }
  };

  return (
    <NotificationContext.Provider value={{openNotification}}>
      {contextHolder}
      {props.children}
    </NotificationContext.Provider>
  );
};
export { NotificationWrapper, NotificationContext };
