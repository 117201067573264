import axios from "axios";
import { BACKEND_URL } from "../../../../config/config";

const backendUrl = BACKEND_URL;

export const onSalaryChange = (
  salary,
  record,
  setIsInputLoading,
  setTaxes,
  form,
  mode,
  openNotification,
  membershipCode
) => {
  const requestBody = {
    salaryWorker: salary?.salaryWorker,
    salaryEtam: salary?.salaryEtam,
    salaryExecutive: salary?.salaryExecutive,
    workForce: parseInt(salary.workForce, 10),
    userId: record?.userId,
    membershipCode: membershipCode,
  };

  setIsInputLoading(true);

  axios
    .post(`${backendUrl}/api/declaration/getTaxesAutocomplete`, requestBody, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      const {
        feeExecutive,
        feeEtam,
        feeWorker,
        tvaExecutive,
        tvaEtam,
        tvaWorker,
        feeAmount,
        membershipCategory,
      } = response.data;
      setTaxes({
        feeExecutive: feeExecutive,
        feeEtam: feeEtam,
        feeWorker: feeWorker,
        tvaExecutive: tvaExecutive,
        tvaEtam: tvaEtam,
        tvaWorker: tvaWorker,
        feeAmount: feeAmount,
      });
      // setNextMembershipCategory({ category: membershipCategory });

      // Update value Taxe in input antd
      form.setFieldsValue({
        salaryExecutiveFee:
          mode === "edit" ? `${feeExecutive} €` : record?.feeExecutiveLocale,
        salaryExecutiveTva:
          mode === "edit" ? `${tvaExecutive} €` : record?.tvaExecutiveLocale,
        salaryEtamFee: mode === "edit" ? `${feeEtam} €` : record?.feeEtamLocale,
        salaryEtamTva: mode === "edit" ? `${tvaEtam} €` : record?.tvaEtamLocale,
        salaryWorkerFee:
          mode === "edit" ? `${feeWorker} €` : record?.feeWorkerLocale,
        salaryWorkerTva:
          mode === "edit" ? `${tvaWorker} €` : record?.tvaWorkerLocale,
        identifier:
          mode === "edit" ? `${feeAmount} €` : record?.feeAmountLocale,
      });
    })
    .catch((error) => {
      console.error("error", error);
      openNotification("Erreur", error.response.data.data, "error");
    })
    .finally(() => {
      setIsInputLoading(false);
    });
};

export const getRecordMonthToString = (record) => {
  if (record?.month.startsWith("0")) {
    return record.month.slice(1);
  } else {
    return record?.month;
  }
};

export const handleMoneyChange = (value) => {
  if (typeof value !== "string") {
    return value;
  }
  let formattedValue = value?.replace(/\s/g, ""); // Remove spaces
  // Replace dots with commas, then commas with dots, and remove extra commas
  formattedValue = formattedValue
    .replace(/\./g, ",")
    .replace(/,/, ".")
    .replace(/,/g, "")
    .replace(/[^0-9.]/g, "");

  if (formattedValue.includes(".")) {
    // Limit to 2 decimals
    if (formattedValue.split(".")[1]?.length >= 2) {
      formattedValue = parseFloat(formattedValue).toFixed(2).toString();
    }
  }

  return formattedValue;
};
