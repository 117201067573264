import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Spin, Modal } from "antd";

function ForceValidateEmailModal(props) {
    const { open, onCancel, onValidate, record } = props;
  const backendUrl = BACKEND_URL;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

    
  return (
    <Modal
    open={open}
    onCancel={onCancel}
    onOk={onValidate}
  >
    <div>
        Finaliser l'inscription de <b>{record?.nextEmail}</b> ? <br />
        Cliquer sur OK finalisera l'inscription.
    </div>
  </Modal>
  );
}

export default ForceValidateEmailModal;
