import React, { useState } from "react";
import { BACKEND_URL } from "../../../config/config";
import axios from "axios";
import { Button } from "../../misc/button/Button";

function PaymentStatusChangePage() {
  const fileInput = React.createRef();
  const backendUrl = BACKEND_URL;
  const [returnData, setReturnData] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const requestBody = {
      file: fileInput.current.files[0],
    };

    axios
      .post(`${backendUrl}/api/payment/status-change`, requestBody, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const data = res.data[0];
        setReturnData(data);
      });
  };

  const importResult = (
    <>
      <h2 style={{ marginTop: 30 }}>Résultat de l'import :</h2>
      <ul>
        {returnData?.map((el) => (
          <li>{el}</li>
        ))}
      </ul>
    </>
  );

  return (
    <div className="info-import">
      <h1>Statut des paiements</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="box-label-input">
            <label>Déposez ici un fichier CSV</label>
            <input type="file" ref={fileInput} />
          </div>
          <Button type="submit">Envoyer</Button>
        </form>
        {returnData?.length > 0 && importResult}
      </div>
    </div>
  );
}

export default PaymentStatusChangePage;
