import axios from "axios";
import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL } from "../../../config/config";
import { Button } from "../../misc/button/Button";
import { Form, Input, Spin } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { NotificationContext } from "../../context/NotificationWrapper";
import SpinLoading from "../../misc/loading/SpinLoading";

function ResetPasswordForm(props) {
  const backendUrl = BACKEND_URL;
  const { openNotification } = useContext(NotificationContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();
  const navigate = useNavigate();

  const onFinish = (values) => {
    setIsLoading(true);
    const requestBody = {
      plainPassword: values.pw1,
    };
    axios
      .post(`${backendUrl}/api/reset-password/reset/${slug}`, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        openNotification("Succès", "Mot de passe changé", "success");
        navigate("/");
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification("Erreur", error.response.data.data, "error");
        console.error("error", error);
      });
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="reset-password-container">
        <h2>Réinitialisation du mot de passe</h2>
        <Form
          form={form}
          name="resetPassword"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="pw1"
            label="Nouveau mot de passe"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            required
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre nouveau mot de passe",
              },
              {
                validator(_, value) {
                  if (
                    /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])(?=\S*[\W])\S*$/.test(
                      value
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Votre mot de passe doit être long de 8 caractère ou plus, et doit contenir au moins 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial."
                    )
                  );
                },
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="pw2"
            label="Confirmez le mot de passe"
            dependencies={["pw1"]}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            required
            rules={[
              {
                required: true,
                message: "Veuillez confirmer votre nouveau mot de passe",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("pw1") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Les deux mots de passe ne correspondent pas")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              type="submit"
              children={isLoading ? <SpinLoading /> : "Valider"}
            />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default ResetPasswordForm;
