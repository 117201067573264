import React from "react";

function MediaLinks() {
  return (
    <div className="block-socialmedialinks">
      <a
        href="https://www.facebook.com/APASBTPASSO"
        title="Rejoignez-nous sur Facebook"
      >
        <span className="fa fa-facebook fa-2x"></span>
      </a>

      <a
        href="https://www.twitter.com/APASBTP"
        title="Rejoignez-nous sur Twitter"
      >
        <span className="fa fa-twitter fa-2x"></span>
      </a>

      <a
        href="http://www.linkedin.com/company/apas-btp"
        title="Rejoignez-nous sur LinkedIn"
      >
        <span className="fa fa-linkedin fa-2x"></span>
      </a>

      <a
        href="https://www.flickr.com/photos/apasbtp/albums"
        title="Rejoignez-nous sur Flickr"
      >
        <span className="fa fa-flickr fa-2x"></span>
      </a>

      <a
        href="http://www.instagram.com/apasbtp"
        title="Rejoignez-nous sur Instagram"
      >
        <span className="fa fa-instagram fa-2x"></span>
      </a>

      <a
        href="http://www.youtube.com/channel/UCSzhazDO6MrWtp8mYVhlkzA"
        title="Rejoignez-nous sur Youtube"
      >
        <span className="fa fa-youtube fa-2x"></span>
      </a>
    </div>
  );
}

export default MediaLinks;
