import React, { useContext, useEffect, useState } from "react";
import { DatePicker, Form, Tooltip } from "antd";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import Qs from "qs";
import locale from "antd/lib/date-picker/locale/fr_FR";
import moment from "moment";
import "moment/locale/fr";
import SearchAutocomplete from "../../misc/searchAutocomplete/SearchAutocomplete.js";
import {
  ClearOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExportOutlined,
  EditOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  EuroOutlined,
  ClockCircleTwoTone,
} from "@ant-design/icons";
import { NotificationContext } from "../../context/NotificationWrapper";
import { Button } from "../../misc/button/Button.js";
import TableDeclarations from "./TableDeclarations.js";
import { AuthContext } from "../../context/AuthProviderWrapper.js";
import EditDeclarationModal from "../declarationDetail-CRUD/EditDeclarationModal.js";
import { useLocation } from "react-router-dom";

const { RangePicker } = DatePicker;

moment.locale("fr");

const dateFormatDateMonthYear = "DD/MM/YYYY";
const dateFormatMonthYear = "MM/YYYY";

export default function DeclarationList() {
  const { user, isAdmin } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(1);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editedRecord, setEditedRecord] = useState(null);
  const [isEditableFlag, setIsEditableFlag] = useState(false);
  const [form] = Form.useForm();
  const { state } = useLocation();

  const [filters, setFilters] = useState({
    page: 1,
    startMonth: state?.startMonth || null,
    endMonth: null,
    startYear: state?.startYear || null,
    endYear: null,
    user: null,
    membershipCategory: null,
    validatedDateStart: null,
    validatedDateEnd: null,
    payed: state?.payed || null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Function to handle screen size changes
  const handleResize = () => {
    setIsMediumScreen(window.innerWidth < 1400 && window.innerWidth > 1278);
    setIsSmallScreen(window.innerWidth < 1278);
  };

  // Attach resize event listener
  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/api/declaration/get?`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: getQueryParams(),
        paramsSerializer: function (params_1) {
          return Qs.stringify(params_1, {
            arrayFormat: "comma",
            encode: false,
          });
        },
      });
      setIsLoading(false);
      setTotalItems(response?.data?.totalCount);
      formatData(response?.data?.items);
    } catch (error) {
      setIsLoading(false);
      openNotification("Erreur", error?.response?.data?.data, "error");
      console.error("error", error);
    }
  };

  // this function is used to form the params of the GET request
  const getQueryParams = () => {
    Object.keys(filters).forEach((key) => {
      if (filters[key] === null || typeof filters[key] === "undefined") {
        delete filters[key];
      }
    });
    return filters;
  };

  const handleDownloadPdf = (element) => {
    axios
      .get(`${BACKEND_URL}/api/declaration/bill-pdf/${element.id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response?.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        const date = new Date(element.imputedDate * 1000);
        const indice = element?.indice > 0 ? `-${element?.indice}` : "";
        const title = `ADH${
          element?.membershipCode
        }-${date.getUTCFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}${indice}-apas-facture.pdf`;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const formatData = (dataList) => {
    let formattedData = {};
    if (isAdmin) {
      formattedData = dataList?.map((el) => ({
        id: el.id,
        key: el.id,
        companyName: <div style={{ fontWeight: "bold" }}>{el.companyName}</div>,
        membershipCode: (
          <div style={{ fontWeight: "bold" }}>{el.membershipCode}</div>
        ),
        membershipCategory: (
          <div style={{ fontWeight: "bold" }}>{el.membershipCategory}</div>
        ),
        feeAmount: (
          <div style={{ fontWeight: "bold" }}>{el.feeAmountLocale}</div>
        ),
        workForce: <div>{el.workForce}</div>,
        imputedDate: el.imputedDate,
        formatedInputedDate: <div>{el.formatedInputedDate}</div>,
        formatedValidatedDate: <div>{el.formatedCreationDate}</div>,
        paymentMethod: <div>{el.paymentMethod}</div>,
        payed:
          el.payed === "yes" ? (
            <Tooltip title="Réglé">
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </Tooltip>
          ) : el.payed === "no" ? (
            <Tooltip title="Non réglé">
              <CloseCircleTwoTone twoToneColor="red" />
            </Tooltip>
          ) : (
            <Tooltip title="En cours de traitement">
              <ClockCircleTwoTone twoToneColor="orange" />
            </Tooltip>
          ),
        edit: (
          <div className="edit-download">
            <Button
              onClick={() => handleEdit(el)}
              classNameElement="btn-edit-download"
              children={<EditOutlined />}
              title="Modifier"
            />

            <Button
              onClick={() => handleDownloadPdf(el)}
              classNameElement="btn-edit-download"
              children={<DownloadOutlined />}
              title="Télécharger Pdf"
            />
          </div>
        ),
      }));
    } else {
      formattedData = dataList?.map((el) => ({
        id: el.id,
        key: el.id,
        companyName: <div style={{ fontWeight: "bold" }}>{el.companyName}</div>,
        membershipCode: (
          <div style={{ fontWeight: "bold" }}>{el.membershipCode}</div>
        ),
        membershipCategory: (
          <div style={{ fontWeight: "bold" }}>{el.membershipCategory}</div>
        ),
        feeAmount: (
          <div style={{ fontWeight: "bold" }}>{el.feeAmountLocale}</div>
        ),
        workForce: <div>{el.workForce}</div>,
        imputedDate: el.imputedDate,
        formatedInputedDate: <div>{el.formatedInputedDate}</div>,
        formatedValidatedDate: <div>{el.formatedCreationDate}</div>,
        paymentMethod: <div>{el.paymentMethod}</div>,
        payed:
          el.payed === "yes" ? (
            <Tooltip title="Réglé">
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </Tooltip>
          ) : el.payed === "no" ? (
            <Tooltip title="Non réglé">
              <CloseCircleTwoTone twoToneColor="red" />
            </Tooltip>
          ) : (
            <Tooltip title="En cours de traitement">
              <ClockCircleTwoTone twoToneColor="orange" />
            </Tooltip>
          ),
        edit: (
          <div className="edit-download">
            <Button
              onClick={() => handleEdit(el)}
              classNameElement="btn-edit-download"
              children={
                el.payed !== "no" ? <InfoCircleOutlined /> : <EuroOutlined />
              }
              title={el.payed !== "no" ? "Consulter" : "Payer"}
            />
            <Button
              onClick={() => handleDownloadPdf(el)}
              classNameElement="btn-edit-download"
              children={<DownloadOutlined />}
            />
          </div>
        ),
      }));
    }
    setData(formattedData);
  };

  const handleTableChange = (e, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: e.current,
      startMonth: filters.startMonth,
      endMonth: filters.endMonth,
      startYear: filters.startYear,
      endYear: filters.endYear,
      user: filters.user,
      membershipCategory: value.membershipCategory,
    }));
  };

  /// fix user id is company id
  const checkIfEditable = (declaration) => {
    if (user.isAdmin && user.id === 34001) {
      return true;
    }
    return false;
  };

  // open modal and set data inside
  const handleEdit = (record) => {
    const flag = checkIfEditable(record);
    setIsEditableFlag(flag);
    setEditedRecord(record);
    setEditModalVisible(true);
  };

  const handleResetFilters = () => {
    setFilters({
      page: 1,
      startMonth: null,
      endMonth: null,
      startYear: null,
      endYear: null,
      user: null,
      membershipCategory: null,
      validatedDateStart: null,
      validatedDateEnd: null,
    });
    setCategory([]);
    setSearchValue("");
  };

  // on OK on the MONTH + YEAR rangepicker
  const handleSendMonthRequest = () => {
    form.validateFields().then((values) => {
      // Accédez aux valeurs du champ RangePicker
      const dateRangeValues = values["monthRange"];

      // Vérifiez si les valeurs sont null ou non
      if (!dateRangeValues || !dateRangeValues[0] || !dateRangeValues[1]) {
        return setFilters({
          ...filters,
          page: 1,
          startMonth: null,
          startYear: null,
          endMonth: null,
          endYear: null,
        });
      } else {
        return setFilters({
          ...filters,
          page: 1,
          startMonth: dateRangeValues[0].month() + 1,
          startYear: dateRangeValues[0].year(),
          endMonth: dateRangeValues[1].month() + 1,
          endYear: dateRangeValues[1].year(),
        });
      }
    });
  };

  // on OK on the DAY MONTH & YEAR rangepicker
  const handleSendvalidatedDateRequest = () => {
    form.validateFields().then((values) => {
      const dateRangeValues = values["dateRange"];

      if (!dateRangeValues || !dateRangeValues[0] || !dateRangeValues[1]) {
        return setFilters({
          ...filters,
          page: 1,
          validatedDateStart: null,
          validatedDateEnd: null,
        });
      } else {
        const startDateString = dateRangeValues[0].format("DD/MM/YYYY");
        const endDateString = dateRangeValues[1].format("DD/MM/YYYY");
        return setFilters({
          ...filters,
          page: 1,
          validatedDateStart: startDateString,
          validatedDateEnd: endDateString,
        });
      }
    });
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    fetchData();
    setIsEditableFlag(false);
  };

  const handleUserSearch = (e) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        page: filters.page,
        startMonth: filters.startMonth,
        endMonth: filters.endMonth,
        startYear: filters.startYear,
        endYear: filters.endYear,
        membershipCategory: filters.membershipCategory,
        user: e?.value,
      };
    });
  };

  const handleUserChange = (e) => {
    setSearchValue(e);
  };

  const handleExportCSV = () => {
    axios
      .get(`${BACKEND_URL}/api/csv/export?`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: getQueryParams(),
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "comma", encode: false });
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response?.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        const date = new Date();
        link.setAttribute(
          "download",
          `declarations_${date.getDate()}_${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}_${date.getUTCFullYear()}.csv`
        );
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <div className="block-right">
      <div className="info-table">
        <div className="input-container">
          {isAdmin && (
            <div className="findUser-container">
              <SearchAutocomplete
                entity="user"
                name="company"
                defaultValue={null}
                style={{ width: "200px" }}
                onValidate={handleUserSearch}
                handleChange={handleUserChange}
                value={searchValue}
              />
            </div>
          )}
          <Form form={form}>
            <div className="findByDate-container">
              <Form.Item name="monthRange">
                <RangePicker
                  picker="month"
                  locale={locale}
                  format={dateFormatMonthYear}
                  placeholder={["date de début", "date de fin"]}
                  style={{ width: isMediumScreen ? "230px" : "auto" }}
                  onCalendarChange={handleSendMonthRequest}
                />
              </Form.Item>
            </div>
          </Form>

          {isAdmin && (
            <Form form={form}>
              <div className="findByDate-container">
                <Form.Item name="dateRange">
                  <RangePicker
                    getPopupContainer={(node) => node.parentNode}
                    locale={locale}
                    format={dateFormatDateMonthYear}
                    placeholder={["date valid. début", "date valid. fin"]}
                    style={{ width: isMediumScreen ? "230px" : "auto" }}
                    onCalendarChange={handleSendvalidatedDateRequest}
                  />
                </Form.Item>
              </div>
            </Form>
          )}

          {isAdmin && (
            <div
              style={{
                display: "flex",
                gap: isMediumScreen || isSmallScreen ? "35px" : "25px",
              }}
            >
              <Button
                onClick={handleResetFilters}
                children={<ClearOutlined />}
                title="Réinitialiser"
              />
              <Button
                onClick={handleExportCSV}
                children={isSmallScreen ? "Export CSV" : <ExportOutlined />}
                title={isSmallScreen ? "" : "Exporter au format CSV"}
              />
            </div>
          )}
        </div>

        <div>
          <TableDeclarations
            category={category}
            handleTableChange={handleTableChange}
            data={data}
            filters={filters}
            totalItems={totalItems}
            isLoading={isLoading}
          />
        </div>
      </div>

      <EditDeclarationModal
        open={editModalVisible}
        onCancel={handleEditModalCancel}
        record={editedRecord}
        mode={isEditableFlag ? "edit" : "view"}
        navigateTo="/declaration-summary"
      />
    </div>
  );
}
