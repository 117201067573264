import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Form, Checkbox } from "antd";
import ribImg from "../../../../assets/rib.png";
import { Button, ButtonLink } from "../../../misc/button/Button";
import { NotificationContext } from "../../../context/NotificationWrapper";
import { BACKEND_URL } from "../../../../config/config";
import axios from "axios";
import SpinLoading from "../../../misc/loading/SpinLoading";

const styles = {
  title: {
    marginBottom: 30,
  },
  image: {
    width: "50%",
    marginTop: 30,
  },
  infoDiv: {
    margin: "0 0 10px 0",
  },
  checkbox: {
    fontSize: "1rem",
  },
  buttonDiv: {
    width: "100%",
    display: "flex",
  },
};

const infoAlertContent = (
  <div>
    <h6>Comment procéder au virement ?</h6>
    <p>
      Connectez vous au site de votre banque et créez, si besoin, un nouveau
      bénéficiaire pour les virements. Indiquer l'IBAN de l'APAS-BTP présent
      ci-dessous et repris sur les factures.
      <br />
      Lors de la création de l'ordre de virement sur votre site bancaire,
      veuillez scrupuleusement reprendre le libellé indiqué sur la facture,
      permettant au Service Comptabilité de l'APAS-BTP de réaliser un
      rapprochement bancaire.
      <br />
      <b>
        Après validation ci-dessous, votre déclaration passera en statut orange.
        Cela signifie que le processus de vérification que les fonds ont été
        effectivement reçus par l'APAS-BTP est en cours. Le statut en vert
        indiquera que les fonds ont été reçus.
      </b>
    </p>
  </div>
);

const iban = "FR76 3006 6105 7100 0105 0452 089";

function DeclarationTransferBankPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const data = state?.message;
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);

  const onFinish = (values) => {
    setIsLoading(true);
    setError("");
    const requestBody = {
      agreeHonorPayment: values.agreeHonorPayment,
    };
    axios
      .post(
        `${BACKEND_URL}/api/payment/pay-by-bank-transfer/${data?.id}`,
        requestBody,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        openNotification("Succès", "Enregistré avec succès", "success");
        navigate("/declaration-summary/success", { state: { message: data } });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("error", error);
        setError(error.response.data.data[0]);
        navigate("/declaration-summary/failure", {
          state: { message: data },
        });
      });
  };

  const errorDisplay = (
    <Alert
      type="error"
      message={error && <p>{error}</p>}
      style={{ width: "700px", alignItems: "center" }}
    />
  );

  return (
    <div className="block-right">
      <Alert
        message={infoAlertContent}
        type="info"
        // closable
        style={{ width: "100%", margin: "auto" }}
      />

      <div className="declaration-payment-page">
        <h2 style={styles.title}>Virement</h2>
        <div>
          <div style={styles.infoDiv}>
            Somme à payer : <b>{data?.feeAmountLocale}</b>
          </div>
          <div style={styles.infoDiv}>
            IBAN à renseigner : <b>{iban}</b>
          </div>
          <div style={styles.infoDiv}>
            <b>Libellé impératif</b> à mentionner sur votre virement :{" "}
            <b>
              ADH{data?.membershipCode}-{data?.year}-{data?.month}
              {data?.indice > 0 && `-${data?.indice}`}
            </b>
          </div>
        </div>
        {error && errorDisplay}
        <Form
          form={form}
          name="paymentAgreement"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{}}
        >
          <Form.Item
            name="agreeHonorPayment"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Veuillez agréer d'honorer le paiement")
                      ),
              },
            ]}
          >
            <Checkbox style={styles.checkbox}>
              Je m'engage à honorer le virement de <b>{data?.feeAmount} €</b> à
              l'APAS.
            </Checkbox>
          </Form.Item>
          <div className="button-container">
            <ButtonLink
              to={"/declaration-certificate-payment"}
              state={{ message: data }}
              children="Retour"
            />

            {/* <Form.Item> */}
            <Button
              type="submit"
              children={isLoading ? <SpinLoading /> : "Valider"}
            />
            {/* </Form.Item> */}
          </div>
        </Form>
        <div>
          <img src={ribImg} style={styles.image} alt="RIB_APAS" />
        </div>
      </div>
    </div>
  );
}

export default DeclarationTransferBankPage;
