import React, { useContext, useState } from "react";
import { Button } from "../../misc/button/Button";
import { Form, Input, Modal, Spin } from "antd";
import { BACKEND_URL } from "../../../config/config";
import axios from "axios";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  ApiOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/AuthProviderWrapper";
import { NotificationContext } from "../../context/NotificationWrapper";
import SpinLoading from "../../misc/loading/SpinLoading";

function DetacheGroup({ page, data, afterClose }) {
  const { isAdmin, authenticateUser, activeCompany, user } =
    useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { openNotification } = useContext(NotificationContext);

  const showModal = () => {
    form.setFieldValue();
    setOpen(true);
  };

  const successModal = () => {
    Modal.success({
      title: "Adhérent Rattaché",
      content: "L'adhérent a été rattaché avec succès",
      onOk() {
        afterClose();
      },
    });
  };

  const handleOk = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then((values) => {
        let requestBody = {
          nextEmail: values.email,
          membershipCode: data.membershipCode,
          plainPassword: values?.password ? values?.password : null,
        };
        return axios.post(
          `${BACKEND_URL}/api/register/change/user`,
          requestBody,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      })
      .then(async () => {
        await authenticateUser();
        setOpen(false);
        successModal();
      })
      .catch((error) => {
        openNotification("Erreur", error.response.data.data, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      {page === "infoDetail" ? (
        <Button
          type="button"
          onClick={showModal}
          children="Transférer cet Adhérent"
          title="Transférer"
        />
      ) : (
        <Button
          onClick={showModal}
          classNameElement="btn-edit-download"
          children={<ApiOutlined />}
          title="Transférer"
        />
      )}

      <Modal
        title="Nouvelle adresse de gestion de l'adhérent"
        open={open}
        // style={{ minWidth: 600 }}
        onCancel={() => {
          setOpen(false);
        }}
        destroyOnClose={true}
        footer={
          <div
            style={{
              marginRight: "10px",
              display: "flex",
              gap: "30px",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={handleCancel}>Retour</Button>
            <Button
              text="Modifier"
              onClick={handleOk}
              children={isLoading ? <SpinLoading /> : "Valider"}
            />
          </div>
        }
      >
        {!isAdmin && (
          <div>
            <p>
              Vous êtes sur le point de transférer la gestion de cet Adhérent.
              Lorsque vous réalisez cette opération, vous ne serez plus en
              mesure de visualiser les déclarations existantes, de réaliser des
              paiements, ou d'en déclarer de nouvelles pour cet Adhérent.
            </p>
          </div>
        )}
        <Form
          className="form-detache"
          form={form}
          name="multiAccount"
          onFinish={handleOk}
          scrollToFirstError
        >
          <Form.Item
            name="email"
            label="Nouvel e-mail"
            required
            rules={[
              {
                type: "email",
                message: "Ce n'est pas une adresse e-mail valide",
              },
              {
                required: true,
                message: "Veuillez entrer votre e-mail",
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email2"
            label="Confirmez"
            dependencies={["email"]}
            hasFeedback
            required
            rules={[
              {
                type: "email",
                message: "Ce n'est pas une adresse e-mail valide",
              },
              {
                required: true,
                message: "Veuillez confirmer votre e-mail",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("email") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Les deux e-mails ne correspondent pas")
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          {/* {!isAdmin && (
            <Form.Item
              name="password"
              label="Mot de passe"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              required
              rules={[
                {
                  required: true,
                  message: "Veuillez entrer votre mot de passe",
                },
                {
                  validator(_, value) {
                    if (
                      /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])(?=\S*[\W])\S*$/.test(
                        value
                      )
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Mauvais mot de passe."));
                  },
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          )} */}
        </Form>
      </Modal>
    </>
  );
}

export default DetacheGroup;
