import React, { useState, createContext, useEffect } from "react";

const DeclarationContext = createContext();

function DeclarationProviderWrapper(props) {
  const [declaration, setDeclaration] = useState();

  useEffect(() => {
    updateDeclaration();
  }, []);

  const updateDeclaration = (newDeclaration) => {
    setDeclaration(newDeclaration);
  };

  const removeDeclaration = () => {
    setDeclaration(null);
  };

  return (
    <DeclarationContext.Provider
      value={{
        declaration,
        setDeclaration,
        updateDeclaration,
        removeDeclaration,
      }}
    >
      {props.children}
    </DeclarationContext.Provider>
  );
}

export { DeclarationProviderWrapper, DeclarationContext };
