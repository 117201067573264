import axios from "axios";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../../config/config";
import { Button, ButtonLink } from "../../misc/button/Button";
import { Select, Space, Checkbox, Spin, Alert } from "antd";
import { NotificationContext } from "../../context/NotificationWrapper";
import { useDebounce } from "../../../hooks/useDebounce";
import SpinLoading from "../../misc/loading/SpinLoading";
import { DeclarationContext } from "../../context/DeclarationWrapper";
import { AuthContext } from "../../context/AuthProviderWrapper";
import { renderCheckboxOrHidden } from "../../helper-function/helperFunction";

function Salary(props) {
  const { isEdit } = props;
  const { user, activeCompany } = useContext(AuthContext);
  const { openNotification } = useContext(NotificationContext);
  const backendUrl = BACKEND_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [isInputLoading, setIsInputLoading] = useState(false);
  const [error, setError] = useState([]);
  const { updateDeclaration } = useContext(DeclarationContext);
  const [conditionCheckbox, setConditionCheckbox] = useState(false);
  const navigate = useNavigate();
  const [nextMembershipCategory, setNextMembershipCategory] = useState({
    category: null,
  });

  const [salary, setSalary] = useState({
    salaryWorker: null,
    salaryEtam: null,
    salaryExecutive: null,
    workForce: null,
    month: null,
    year: null,
    agreeOptionalWorker: null,
  });

  const [taxes, setTaxes] = useState({
    feeExecutive: 0,
    feeEtam: 0,
    feeWorker: 0,
    tvaExecutive: 0,
    tvaEtam: 0,
    tvaWorker: 0,
    feeAmount: 0,
  });

  useEffect(() => {
    const condition = renderCheckboxOrHidden(user, salary);
    setConditionCheckbox(condition);
  }, [user, salary]);

  const abortControllerRef = useRef(null);
  const debouncedSalaryExecutive = useDebounce(salary.salaryExecutive);
  const debouncedSalaryEtam = useDebounce(salary.salaryEtam);
  const debouncedSalaryWorker = useDebounce(salary.salaryWorker);
  const debouncedWorkForce = useDebounce(salary.workForce);

  useEffect(() => {
    if (
      typeof debouncedSalaryExecutive === "undefined" &&
      typeof debouncedSalaryEtam === "undefined" &&
      typeof debouncedSalaryWorker === "undefined" &&
      typeof debouncedWorkForce === "undefined"
    ) {
      return;
    }
    onSalaryChange();
  }, [
    debouncedSalaryExecutive,
    debouncedSalaryEtam,
    debouncedSalaryWorker,
    debouncedWorkForce,
  ]);

  // Set default values based on current date
  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

    // Update the state with default values if not already selected
    setSalary((prevSalary) => ({
      month: prevSalary.month || currentMonth.toString(),
      year: prevSalary.year || currentYear.toString(),
    }));
  }, []);

  const onSalaryChange = () => {
    const payload = {
      salaryWorker: salary?.salaryWorker,
      salaryEtam: salary?.salaryEtam,
      salaryExecutive: salary?.salaryExecutive,
      workForce: parseInt(salary?.workForce, 10),
      membershipCode: activeCompany.membershipCode,
    };
    setIsInputLoading(true);
    axios
      .post(`${backendUrl}/api/declaration/getTaxesAutocomplete`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        const {
          feeExecutive,
          feeEtam,
          feeWorker,
          tvaExecutive,
          tvaEtam,
          tvaWorker,
          feeAmount,
          membershipCategory,
        } = response.data;
        console.log("response.data", response.data);
        setTaxes({
          feeExecutive: feeExecutive,
          feeEtam: feeEtam,
          feeWorker: feeWorker,
          tvaExecutive: tvaExecutive,
          tvaEtam: tvaEtam,
          tvaWorker: tvaWorker,
          feeAmount: feeAmount,
        });
        console.log("taxes", taxes);
        setNextMembershipCategory({ category: membershipCategory });
      })
      .catch((error) => {
        console.error("error", error);
        openNotification("Erreur", error.response.data.data, "error");
      })
      .finally(() => {
        setIsInputLoading(false);
      });
  };

  const handleChange = (e) => {
    setSalary((prevSalary) => ({
      ...prevSalary,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  const handleMoneyChange = (e) => {
    // imagine an input like "r1234,5.67"
    // we replace all dots by commas -> r1234,5,67
    e.target.value = e.target.value.replace(/\./g, ",");
    // next, we replace the first comma by a dot -> r1234.5,67
    e.target.value = e.target.value.replace(/,/, ".");
    // removal af all others commas -> r1234.567
    e.target.value = e.target.value.replace(/,/g, "");
    // finally we get rid of everything that is nor a number nor a dot _> 1234.567
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");

    // 2 decimals max after comma
    if (e.target.value.includes(".")) {
      if (e.target.value.split(".")[1]?.length >= 2) {
        var num = parseFloat(e.target.value);
        var cleanNum = num.toFixed(2);
        e.target.value = cleanNum.toString();
      }
    }
    return e;
  };

  const handleChangeMonth = (e) => {
    setSalary({ ...salary, month: e });
  };

  const handleChangeYear = (e) => {
    setSalary({ ...salary, year: e });
  };

  const checkAndSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const [requestDeclaration, setRequestDeclaration] = useState({
    ...salary,
    membershipCode: activeCompany.membershipCode,
  });

  useEffect(() => {
    setRequestDeclaration((prev) => ({
      ...prev,
      ...salary,
      workForce: parseInt(salary?.workForce, 10),
      agreeOptionalWorker: salary?.agreeOptionalWorker ?? false,
      membershipCode: activeCompany.membershipCode,
    }));
  }, [activeCompany, salary]);

  const handleSubmit = () => {
    setIsLoading(true);
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    setError({});

    axios
      .post(
        `${backendUrl}/api/declaration/check-validity`,
        requestDeclaration,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          signal: abortControllerRef.current?.signal,
        }
      )
      .then((res) => {
        return res.data.data;
      })
      .then((res) => {
        updateDeclaration(res);
        navigate("/declaration-summary", { state: { message: res } });
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        Array.isArray(error.response.data?.data)
          ? setError(error.response.data.data)
          : setError([error.response.data.data]);
        console.error("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPdf = (id) => {
    axios
      .get(`${BACKEND_URL}/api/declaration/create-bill/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const getCurrentMonthToString = () => {
    const d = new Date();
    let month = d.getMonth() + 1;
    return month.toString();
  };

  const getCurrentYearToString = () => {
    const d = new Date();
    let year = d.getFullYear();
    return year.toString();
  };

  const errorDisplay = (
    <Alert
      type="error"
      message={error?.length > 0 && error.map((el) => <p>{el}</p>)}
      style={{ width: "780px", alignItems: "center" }}
    />
  );

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="info-salary">
        {error?.length > 0 && errorDisplay}
        <form onSubmit={checkAndSubmit}>
          <table>
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Salaire</th>
                <th scope="col">Cotisation (0,4%)</th>
                <th scope="col">TVA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Salaire Brut Cadres</th>
                <td>
                  <input
                    type="text"
                    name="salaryExecutive"
                    onChange={(event) => handleChange(handleMoneyChange(event))}
                    value={salary?.salaryExecutive}
                    inputMode="numeric"
                    required
                  />
                </td>
                <td>
                  {isInputLoading && (
                    <div className="input-style">
                      <Spin spinning={isInputLoading} />
                    </div>
                  )}
                  {!isInputLoading && (
                    <input
                      type="text"
                      name="salaryExecutiveFee"
                      className="text-align-right"
                      value={`${taxes?.feeExecutive} €`}
                      required
                      readOnly
                    />
                  )}
                </td>
                <td>
                  {isInputLoading && (
                    <div className="input-style">
                      <Spin spinning={isInputLoading} />
                    </div>
                  )}
                  {!isInputLoading && (
                    <input
                      type="text"
                      name="salaryExecutiveTva"
                      className="text-align-right"
                      value={`${taxes?.tvaExecutive} €`}
                      required
                      readOnly
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th>Salaire Brut ETAM</th>
                <td>
                  <input
                    type="text"
                    name="salaryEtam"
                    step={0.01}
                    min={0}
                    onChange={(event) => handleChange(handleMoneyChange(event))}
                    value={salary?.salaryEtam}
                    inputMode="numeric"
                    required
                  />
                </td>
                <td>
                  {isInputLoading && (
                    <div className="input-style">
                      <Spin spinning={isInputLoading} />
                    </div>
                  )}
                  {!isInputLoading && (
                    <input
                      type="text"
                      name="salaryEtamFee"
                      className="text-align-right"
                      value={`${taxes?.feeEtam} €`}
                      required
                      readOnly
                    />
                  )}
                </td>
                <td>
                  {isInputLoading && (
                    <div className="input-style">
                      <Spin spinning={isInputLoading} />
                    </div>
                  )}
                  {!isInputLoading && (
                    <input
                      type="text"
                      name="salaryEtamTva"
                      className="text-align-right"
                      value={`${taxes?.tvaEtam} €`}
                      required
                      readOnly
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  Salaire Brut Ouvriers {salary?.workForce > 10 ? "(opt.)" : ""}
                </th>
                <td>
                  <input
                    type="text"
                    step={0.01}
                    min={0}
                    onChange={(event) => handleChange(handleMoneyChange(event))}
                    name="salaryWorker"
                    value={salary?.salaryWorker}
                    inputMode="numeric"
                  />
                </td>
                <td>
                  {isInputLoading && (
                    <div className="input-style">
                      <Spin spinning={isInputLoading} />
                    </div>
                  )}
                  {!isInputLoading && (
                    <input
                      type="text"
                      name="salaryWorkerFee"
                      className="text-align-right"
                      value={`${taxes.feeWorker} €`}
                      readOnly
                    />
                  )}
                </td>
                <td>
                  {isInputLoading && (
                    <div className="input-style">
                      <Spin spinning={isInputLoading} />
                    </div>
                  )}
                  {!isInputLoading && (
                    <input
                      type="text"
                      name="salaryWorkerTva"
                      className="text-align-right"
                      value={`${taxes?.tvaWorker} €`}
                      readOnly
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th>Date</th>
                <td data-testid="testMonth">
                  <Space wrap>
                    <Select
                      defaultValue={getCurrentMonthToString}
                      className="select"
                      onChange={handleChangeMonth}
                      options={[
                        { value: "1", label: "Janvier" },
                        { value: "2", label: "Février" },
                        { value: "3", label: "Mars" },
                        { value: "4", label: "Avril" },
                        { value: "5", label: "Mai" },
                        { value: "6", label: "Juin" },
                        { value: "7", label: "Juillet" },
                        { value: "8", label: "Aout" },
                        { value: "9", label: "Septembre" },
                        { value: "10", label: "Octobre" },
                        { value: "11", label: "Novembre" },
                        { value: "12", label: "Décembre" },
                      ]}
                    />
                  </Space>
                </td>
                <td>
                  <Space wrap>
                    <Select
                      data-testid="year"
                      defaultValue={getCurrentYearToString}
                      className="select"
                      onChange={handleChangeYear}
                      options={[
                        { value: "2024", label: "2024" },
                        { value: "2023", label: "2023" },
                      ]}
                    />
                  </Space>
                </td>
              </tr>
              <tr>
                <th>Effectifs</th>
                <td>
                  <input
                    type="number"
                    min={0}
                    step={1}
                    name="workForce"
                    value={salary?.workForce}
                    onChange={handleChange}
                    inputMode="numeric"
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>Montant dû</th>
                <td>
                  {isInputLoading && (
                    <div className="input-style">
                      <Spin spinning={isInputLoading} />
                    </div>
                  )}
                  {!isInputLoading && (
                    <input
                      type="text"
                      name="identifier"
                      className="text-align-right"
                      value={`${taxes.feeAmount} €`}
                      disabled
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {/* end of class salary-grid-part-one */}
          {/* Condition : user.laborUnion !== 2 && salary?.workForce > 10 */}
          {conditionCheckbox && (
            <div className="flex-row visible">
              <div>
                <Checkbox
                  onChange={handleChange}
                  name="agreeOptionalWorker"
                  checked={salary?.agreeOptionalWorker}
                  style={{ marginTop: "20px", paddingBottom: "30px" }}
                  required
                >
                  J'ai pris connaissance que la souscription aux Prestations
                  pour les Ouvriers est optionnelle si les effectifs sont
                  supérieurs à 10.
                </Checkbox>
              </div>
            </div>
          )}
          {!isEdit && (
            <div className="button-container" data-testid="isEdit">
              <ButtonLink to="/infoIdentifier" text="Retour">
                Retour
              </ButtonLink>
              <Button
                type="submit"
                children={isLoading ? <SpinLoading /> : "Valider"}
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
}

export default Salary;
