import React from "react";
import { Button, ButtonLink } from "../../../misc/button/Button";
import SpinLoading from "../../../misc/loading/SpinLoading";
import { Form } from "antd";

export const TitleEditDeclarationModal = (record) => {
  let title;
  if (record?.payed === "yes") {
    title = "Déclaration payée";
  } else if (record?.payed === "ongoing") {
    title = "Déclaration en cours de traitement";
  } else {
    title = "Déclaration à payer";
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <div>{title}</div>
      <div style={{ fontWeight: "normal", fontSize: 17, marginRight: 50 }}>
        {record?.indice > 0 && `Indice de facture : ${record?.indice}`}
      </div>
    </div>
  );
};

export const FooterEditDeclarationModal = ({
  mode,
  isLoading,
  onCloseModal,
  record,
  isAdmin,
}) => {
  const editFooter = (
    <div className="button-container-modal-edit">
      <div>
        <Button text="Retour" onClick={onCloseModal}>
          Retour
        </Button>
      </div>
      <Form.Item>
        <Button
          text="Modifier"
          type="submit"
          children={isLoading ? <SpinLoading /> : "Modifier"}
        />
      </Form.Item>
    </div>
  );

  const viewFooter = (
    <div className="button-container-modal-view">
      <Button text="Retour" onClick={onCloseModal}>
        Retour
      </Button>
      {record?.payed === "no" && !isAdmin && (
        <>
          <ButtonLink
            to="/declaration-cb"
            state={{ message: record }}
            children="Payer par CB"
          />
          <ButtonLink
            to="/declaration-payment"
            state={{ message: record }}
            children=" Payer par virement"
          />
        </>
      )}
    </div>
  );

  return mode === "edit" ? editFooter : viewFooter;
};
