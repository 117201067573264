import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Alert, Spin } from "antd";
import { BACKEND_URL, OGONE_URL } from "../../../../config/config";
import axios from "axios";
import { ButtonLink } from "../../../misc/button/Button";

const styles = {
  title: {
    marginBottom: 30,
  },
  image: {
    width: "50%",
    marginTop: 30,
  },
  infoDiv: {
    margin: "0 0 10px 0",
  },
  checkbox: {
    fontSize: "1rem",
  },
  buttonDiv: {
    width: "100%",
    display: "flex",
  },
};

const infoAlertContent = (
  <div>
    <h6>Comment procéder au virement ?</h6>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
      <br />
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
      voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      <br />
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
      deserunt mollit anim id est laborum.
    </p>
  </div>
);

export default function DeclarationCBPage() {
  const { state } = useLocation();
  const data = state?.message;
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);
  const [formData, setFormData] = useState({
    AMOUNT: 0,
    CURRENCY: "EUR",
    LANGUAGE: "fr_FR",
    ORDERID: "",
    PSPID: "",
    SHASIGN: "",
    HOMEURL: "",
  });

  useEffect(() => {
    paymentByCB();
  }, []);

  useEffect(() => {
    if (!isSubmited && formData.ORDERID !== "") {
      setIsSubmited(true);
      document.getElementById("redirectForm").submit();
    }
  }, [formData, isSubmited]);

  const paymentByCB = () => {
    return axios
      .get(`${BACKEND_URL}/api/payment/pay-by-cb/${data?.id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const formatedData = {
          AMOUNT: res.data.data.AMOUNT,
          CURRENCY: res.data.data.CURRENCY,
          LANGUAGE: res.data.data.LANGUAGE,
          ORDERID: res.data.data.ORDERID,
          PSPID: res.data.data.PSPID,
          SHASIGN: res.data.data.SHASIGN,
          HOMEURL: res.data.data.HOMEURL,
        };
        return formatedData;
      })
      .then((res) => {
        setFormData({ ...res });
        return res;
      })
      .catch((error) => {
        console.error("error", error);
        setError(error.response.data.data[0]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const errorDisplay = (
    <Alert
      type="error"
      message={error && <p>{error}</p>}
      className="error-display"
    />
  );

  return (
    <div className="block-right">
      <div className="declaration-payment-page">
        <h2 style={styles.title}>Carte Bleue</h2>
        <div>
          <div style={styles.infoDiv}>
            Somme à payer : <b>{data?.feeAmountLocale}</b>
          </div>
          <p>
            Vous allez être redirigé vers le site de la banque. veuillez
            patienter ....
          </p>
          <Spin spinning={isLoading} />
        </div>
        {error && errorDisplay}
        <form id="redirectForm" method="POST" action={OGONE_URL}>
          <input type="hidden" name="PSPID" value={formData.PSPID} />
          <input type="hidden" name="ORDERID" value={formData.ORDERID} />
          <input type="hidden" name="AMOUNT" value={formData.AMOUNT} />
          <input type="hidden" name="CURRENCY" value="EUR" />
          <input type="hidden" name="LANGUAGE" value="fr_FR" />
          <input type="hidden" name="SHASIGN" value={formData.SHASIGN} />
        </form>
        <div style={styles.buttonDiv}>
          <ButtonLink
            to={"/declaration-certificate-payment"}
            state={{ message: data }}
            children="Retour"
          />
        </div>
      </div>
    </div>
  );
}
