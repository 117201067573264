import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthProviderWrapper";
import InfoRow from "./InfoRow";
import { ButtonLink } from "../../misc/button/Button";
import { Checkbox, Alert, Spin } from "antd";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import { renderCheckboxOrHidden } from "../../helper-function/helperFunction";
import ModalSelectionCompany from "../multiAccount/ModalSelectionCompany";

const infoAlertContent = (
  <div>
    <h6>
      Nous vous invitons à vérifier l'ensemble des « informations adhérent » et
      à les modifier si elles ne correspondent pas à la situation de votre
      entreprise.
    </h6>
    <p>
      <b>
        Conventions régionales IDF (hors 77) des secteurs Bâtiment et Travaux
        Publics :
      </b>{" "}
      Les conventions régionales IDF (hors 77) des secteurs du Bâtiment et
      Travaux Publics en vigueur prévoient le versement d'une cotisation
      obligatoire déterminée par un pourcentage du montant des salaires payés
      aux salariés déclarés pour les catégories professionnelles concernées. Par
      salaire, on doit comprendre tout ce qui constitue la rémunération du
      travailleur. Pour la (ou les) catégorie(s) professionnelle(s) non visées
      par une quelconque convention, l'Adhérent pourra opter pour le versement
      d'une cotisation volontaire en complément de celle(s) obligatoire(s) pour
      l'autre (ou les) autre(s) catégorie(s) professionnelle(s).
      <br />
      <br />
      <b>Adhésion Volontaire : </b> Les entreprises du Bâtiment situées dans le
      77, celles annexes ou connexes à la profession ainsi que les organismes
      professionnels du BTP peuvent adhérer volontairement à l'Association.
    </p>
  </div>
);

function InfoDetail() {
  const { user, setUser, activeCompany, setUserCompanies } =
    useContext(AuthContext);
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const receivedDataIdentifier = state?.message;
  const [conditionCheckbox, setConditionCheckbox] = useState(false);

  useEffect(() => {
    const condition = renderCheckboxOrHidden(user, null);
    setConditionCheckbox(condition);
  }, [user]);

  useEffect(() => {
    const getUser = async () => {
      await axios
        .get(`${BACKEND_URL}/api/login/user_info`, {
          withCredentials: true,
        })
        .then((response) => {
          const user = response.data;
          setIsLoading(false);
          setUser(user);
          setUserCompanies(user.companies);
        })
        .catch((error) => {
          console.error("Failed to fetch user info:", error);
        });
    };
    getUser();
  }, []);

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <Alert
        message={infoAlertContent}
        type="info"
        showIcon
        closable
        style={{ alignItems: "center", margin: "auto", width: "100%" }}
      />

      <ModalSelectionCompany />

      <div className="info-detail">
        <div className="info-header">
          <h2>Informations Adhérent</h2>
        </div>

        <div className="info-identifiant">
          <div className="col1">
            <InfoRow
              left="Identifiant APAS :"
              right={receivedDataIdentifier || activeCompany?.membershipCode}
              width="150"
              idToTest="membershipCode-infodetail"
            />
            <InfoRow
              left="Raison sociale :"
              right={activeCompany?.companyName}
              width="150"
            />
            <InfoRow left="E-mail :" right={user?.email} width="150" />
          </div>
        </div>

        <div className="info-adherent-effectif">
          <div className="adherent">
            <InfoRow
              left="Adhérent :"
              right={
                activeCompany?.laborUnion === 2
                  ? "CAPEB"
                  : activeCompany?.laborUnion === 3
                  ? "FFB"
                  : "aucun"
              }
            />
          </div>

          <div className="effectif">
            <InfoRow
              left="Effectifs :"
              right={
                activeCompany?.workForce === "moreThan10"
                  ? "Plus de 10 salariés"
                  : activeCompany?.workForce === "upTo10"
                  ? "Jusqu'à 10 salariés"
                  : "Pas de données"
              }
            />
          </div>
          <div className="box-label-input">
            <Checkbox
              disabled
              checked={activeCompany?.buildingConventionOutside77}
              name="buildingConventionOutside77"
              className="checkbox"
            >
              <span style={{ color: "black", fontWeight: "bold" }}>
                Conventions du Bâtiment hors 77
              </span>
            </Checkbox>
          </div>

          {/* Condition: company?.workForce === "moreThan10" && company?.laborUnion !== 2 */}
          {conditionCheckbox && (
            <div className="box-label-input">
              <Checkbox
                disabled
                checked={activeCompany?.agreeOptionalWorker}
                name="agreeOptionalWorker"
                className="checkbox"
              >
                <span style={{ color: "black", fontWeight: "bold" }}>
                  J'ai pris connaissance que la souscription aux Prestations
                  pour les Ouvriers est optionnelle
                </span>
              </Checkbox>
            </div>
          )}
        </div>

        <div className="info-address">
          <div className="address">
            <InfoRow left="Adresse :" right={activeCompany?.address2} />
          </div>
          <div className="zipcode">
            <InfoRow left="Code postal :" right={activeCompany?.zipcode} />
            <InfoRow left="Ville :" right={activeCompany?.city} />
          </div>
          <div className="siren">
            <InfoRow left="SIREN :" right={activeCompany?.siren} />
          </div>
        </div>

        <div className="info-referent">
          <h3>Représentant légal ou Référent</h3>

          <div className="name-container">
            <InfoRow
              left="Nom :"
              right={activeCompany?.representativeLastname}
            />
            <InfoRow
              left="Prénom :"
              right={activeCompany?.representativeFirstname}
            />
          </div>
          <div className="function-tel-container">
            <InfoRow
              left="Fonction :"
              right={activeCompany?.representativeFunction}
            />
            <InfoRow left="Tél :" right={activeCompany?.representativePhone} />
          </div>
        </div>

        <div className="block-button">
          <ButtonLink to="/infoIdentifier/infoDetail/update">
            Modifier mes informations
          </ButtonLink>
        </div>
      </div>
    </>
  );
}

export default InfoDetail;
