import React from "react";
import { Button, ButtonLink } from "../../misc/button/Button";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";

function DeclarationCertificatePayment() {
  const { state } = useLocation();
  const CreateDeclarationd = state?.message;

  const handleDownloadPdf = (id) => {
    axios
      .get(`${BACKEND_URL}/api/declaration/bill-pdf/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        const date = new Date(CreateDeclarationd.inputedDdate * 1000);
        const title = `ADH${
          CreateDeclarationd?.membershipCode
        }-${date.getUTCFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-apas-facture.pdf`;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <div className="block-right">
      <div className="info-declaration">
        <h4 style={{ textTransform: "uppercase" }}>Certificat de dépôt </h4>
        <p
          style={{ fontStyle: "italic", fontWeight: "900", marginTop: "10px" }}
        >
          Votre déclaration est maintenant terminée. Cette facture est délivrée
          par l'APAS-BTP et atteste du dépôt de votre déclaration. Celle-ci sera
          consultable en utilisant le service « Consulter mon compte adhérent »
          accessible à partir de votre espace adhérent.
        </p>
        <Button
          onClick={() => handleDownloadPdf(CreateDeclarationd?.id)}
          children="Télécharger la facture"
        />
      </div>
      <div className="info-payment">
        <h4 style={{ textTransform: "uppercase" }}>
          Accès au service de paiement{" "}
        </h4>
        <p
          style={{ fontStyle: "italic", fontWeight: "900", marginTop: "10px" }}
        >
          Vous pouvez payer en cliquant sur l'un des boutons ci-dessous.
        </p>

        <div className="button-container">
          <ButtonLink
            to="/declaration-cb"
            state={{ message: CreateDeclarationd }}
            children="Payer par CB"
          />
          <ButtonLink
            to="/declaration-payment"
            state={{ message: CreateDeclarationd }}
            children="Payer par virement"
          />
        </div>
      </div>
    </div>
  );
}

export default DeclarationCertificatePayment;
