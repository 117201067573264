import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthProviderWrapper";
import { Spin } from "antd";

const IsPrivate = (props) => {
  const { isLoggedIn, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <div className="loading">
        <Spin spinning={true} fullscreen />
      </div>
    );
  } else if (!isLoggedIn) {
    return <Navigate to="/" />;
  } else {
    return props.children;
  }
};

export default IsPrivate;
