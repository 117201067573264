import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Checkbox, Spin } from "antd";
import { Button, ButtonLink } from "../../misc/button/Button";
import { AuthContext } from "../../context/AuthProviderWrapper";
import { DeclarationContext } from "../../context/DeclarationWrapper";
import { useMediaQuery } from "react-responsive";
import { renderCheckboxOrHidden } from "../../helper-function/helperFunction";
import EditDeclarationModal from "../declarationDetail-CRUD/EditDeclarationModal";

function DeclarationSummaryPage() {
  const { user, activeCompany } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isEditableFlag, setIsEditableFlag] = useState(false);
  const { declaration, updateDeclaration } = useContext(DeclarationContext);
  const isSmallScreen = useMediaQuery({ maxWidth: 990 });
  const [conditionCheckbox, setConditionCheckbox] = useState(false);

  useEffect(() => {
    const condition = renderCheckboxOrHidden(user, declaration);
    setConditionCheckbox(condition);
  }, [user, declaration]);

  useEffect(() => {
    updateDeclaration((prev) => ({
      ...prev,
      membershipCode: activeCompany.membershipCode,
    }));
  }, [activeCompany]);

  const checkIfEditable = (declaration) => {
    if (!declaration?.id || (declaration?.payed === "no" && user.isAdmin)) {
      return true;
    }
    return false;
  };

  const handleEdit = (record) => {
    const flag = checkIfEditable(record);
    setIsEditableFlag(flag);
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setIsEditableFlag(false);
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="block-right">
        <div className="declaration-summary-page">
          <h2>Récapitulatif de votre déclaration</h2>
          <div className="info-salary">
            <div>
              <table>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Salaire</th>
                    <th scope="col">Cotisation (0,4%)</th>
                    <th scope="col">TVA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Salaire Brut Cadres</th>
                    <td>
                      <div className="input">
                        {declaration?.salaryExecutiveLocale}
                      </div>
                    </td>
                    <td>
                      <div className="input">
                        {declaration?.feeExecutiveLocale}
                      </div>
                    </td>
                    <td>
                      <div className="input">
                        {declaration?.tvaExecutiveLocale}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Salaire Brut ETAM</th>
                    <td>
                      <div className="input">
                        {declaration?.salaryEtamLocale}
                      </div>
                    </td>
                    <td>
                      <div className="input">{declaration?.feeEtamLocale}</div>
                    </td>
                    <td>
                      <div className="input">{declaration?.tvaEtamLocale}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Salaire Brut Ouvriers{" "}
                      {declaration?.workForce > 10 ? "(opt.)" : ""}
                    </th>
                    <td>
                      <div className="input">
                        {declaration?.salaryWorkerLocale}
                      </div>
                    </td>
                    <td>
                      <div className="input">
                        {declaration?.feeWorkerLocale}
                      </div>
                    </td>
                    <td>
                      <div className="input">
                        {declaration?.tvaWorkerLocale}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>
                      <div className="input">
                        {declaration?.formatedInputedDate}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Effectifs</th>
                    <td>
                      <div className="input">{declaration?.workForce}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Montant dû</th>
                    <td>
                      <div className="input">
                        {declaration?.feeAmountLocale}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Condition: user.laborUnion !== 2 && declaration?.workForce > 10 */}
            {conditionCheckbox && (
              <div
                style={{ height: isSmallScreen ? "100px" : "50px" }}
                className="flex-row visible"
              >
                <div style={{ marginTop: "20px" }}>
                  <Checkbox
                    name="agreeOptionalWorker"
                    checked={declaration?.agreeOptionalWorker}
                    disabled={true}
                  >
                    J'ai pris connaissance que la souscription aux Prestations
                    pour les Ouvriers est optionnelle si les effectifs sont
                    supérieurs à 10.
                  </Checkbox>
                </div>
              </div>
            )}
          </div>
          <div className="button-container">
            <ButtonLink to="/declaration" children="Retour" />
            <Button
              // onClick={() => handleEdit(declarationData)}
              onClick={() => {
                handleEdit(declaration);
              }}
              children="Modifier"
            />
            <ButtonLink to="/declaration-signature" children="Valider" />

            <EditDeclarationModal
              open={editModalVisible}
              onCancel={handleEditModalCancel}
              record={declaration}
              mode={isEditableFlag ? "edit" : "view"}
              navigateTo="/declaration-summary"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DeclarationSummaryPage;
