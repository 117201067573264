import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthProviderWrapper";

function FooterPage() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <>
      {isLoggedIn && (
        <footer className="footer-container">
          <Link to="/mentions-legales">Mentions légales</Link>
          <Link to="/charte-adherent">Charte de l'adhérent</Link>
          <a href="mailto:adhesion@apas.asso.fr">Nous contacter</a>
        </footer>
      )}
    </>
  );
}

export default FooterPage;
