import React from "react";
import InfoDetail from "./InfoDetail";

function InfoPage() {
  return (
    <div className="block-right">
      <InfoDetail />
    </div>
  );
}

export default InfoPage;
