import React, { useContext, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { useMediaQuery } from "react-responsive";
import MediaLinks from "./MediaLinks";
import AccountMenu from "./userInfo/AccountMenu";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthProviderWrapper";
import IconUser from "./userInfo/IconUser";
import MenuNavigation from "../left-menuNavigation/MenuNavigation";
import UserZoneTitle from "./UserZoneTitle";
import { ButtonLink } from "../misc/button/Button";
import ModalIconUser from "./userInfo/ModalIconUser";

function Header() {
  const { isLoggedIn, logOutUser } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery({ maxWidth: 990 });
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    logOutUser();
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(!showModal);
  };

  // Fermer le modal lorsqu'on clique n'importe où sur l'écran
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showModal && !event.target.closest("#modal-root")) {
        if (!event.target.closest(".icon-user")) {
          setShowModal(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showModal]);

  return (
    <Navbar className="bg-body-tertiary" id="header-container" expand="lg">
      <Container>
        <Navbar.Brand style={{ width: "30%" }}>
          <Logo />
        </Navbar.Brand>

        {!isSmallScreen && <UserZoneTitle />}

        <div className="right-responsive">
          {isSmallScreen && (
            <Link onClick={handleShow} className="mr-3 icon-user">
              <IconUser />
            </Link>
          )}
          {isSmallScreen && showModal && isLoggedIn && (
            <div className="modal-auth modal-auth-mobile">
              <ModalIconUser handleLogout={handleLogout} />
            </div>
          )}

          {isSmallScreen && showModal && !isLoggedIn && (
            <div
              className="modal-auth modal-auth-mobile"
              style={{ alignItems: "center", width: "200px" }}
            >
              <ButtonLink
                className="icon-user"
                to="/"
                children="Connexion"
                style={{ paddingLeft: 0 }}
              />
            </div>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>

        <Navbar.Collapse className="mediaLinks-userInfo">
          <Nav>
            <MediaLinks />
          </Nav>
          {!isSmallScreen && (
            <Nav>
              <AccountMenu
                handleLogout={handleLogout}
                handleShow={handleShow}
                showModal={showModal}
              />
            </Nav>
          )}
          {isSmallScreen && isLoggedIn && <MenuNavigation />}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
