import React from "react";
import { Link } from "react-router-dom";

function LegalMentionsPage() {
  return (
    <div className="terms-service">
      <h2>MENTIONS LÉGALES</h2>
      <div
        style={{
          color: "white",
          backgroundColor: "#ee463b",
          marginBottom: 30,
          padding: "25px 20px",
        }}
      >
        Ce site est édité par l'Association Paritaire d'Action Sociale du
        Bâtiment et des Travaux Publics (APAS-BTP), association régie par la loi
        du 1er juillet 1901, déclarée à la Préfecture de Police de Paris le 23
        mai 1946, dont le siège social est situé 14-18, rue de la Vanne - 92120
        Montrouge | Tél. 01 84 990 990
        <br />
        <br />
        N° SIRET : 775 682 313 00520 - APE : 6619B
        <br />
        <br />
        Titulaire du Certificat d'Immatriculation Tourisme N° IM075110023
        délivrée par le Registre des Opérateurs de Voyages et de Séjours Atout
        France selon les dispositions prévues par l'article R. 211-21 du code du
        tourisme.
        <br />
        <br />
        La garantie financière est apportée par : ATRADIUS CREDITO Y CAUCION
        S.A. DE SEGUROS Y REASEGUROS, 159 RUE ANATOLE FRANCE, 92596
        LEVALLOIS-PERRET, FRANCE
        <br />
        <br />
        L'assurance de responsabilité civile professionnelle est souscrite
        auprès de : MMA IARD, 14 BOULEVARD MARIE ET ALEXANDRE OYON, 72030 LE
        MANS, FRANCE
        <br />
      </div>
      <div className="content-part">
        <>
          <h3>CNIL</h3>
          <p>
            Conformément à la loi n° 78-17 du 6 janvier 1978 « relative à
            l'informatique, aux fichiers et aux libertés », ce site a été
            déclaré à la CNIL (Commission Nationale de l'Informatique et des
            Libertés -{" "}
            <a href="https://www.cnil.fr/fr">
              <b>www.cnil.fr</b>
            </a>{" "}
            ) sous le numéro : en cours d'enregistrement..{" "}
          </p>

          {/* <h3>HÉBERGEUR</h3>
            <p>
            CLARANET SAS<br />
            18-20, rue du Faubourg du Temple 75011 Paris<br />
            RCS de Paris B 419 632 286<br />
            </p>
            
            <h3>DÉVELOPPEUR</h3>
            <p>
            Ce site est édité par OZITEM, SARL au capital de 50 496 euros,<br />
            53 rue Baudin, 92300 Levallois-Perret.<br />
            RCS B Paris 18-04-1997<br />
            Siret : 41176117400068<br />
            Code APE : 6202A<br />
            Numéro TVA intracommunautaire :<br />
            </p> */}

          <h3>DIRECTION DE LA PUBLICATION</h3>
          <p>APAS-BTP</p>

          <h3>DONNÉES PERSONNELLES</h3>
          <p>
            En France et en Europe, les données personnelles sont protégées par
            les textes suivants : loi n° 78-17 du 6 janvier 1978, article 226-13
            du Code Pénal, Directive européenne du 24 octobre 1995.
            <br />
            Vous trouverez ci-après le type d'informations que l'APAS-BTP est en
            mesure de recueillir lorsque vous visitez son site{" "}
            <a href="https://adhesion.apas.asso.fr">
              <b>adhesion.apas.asso.fr</b>
            </a>{" "}
            ainsi que la manière dont elles seront utilisées ultérieurement.
          </p>

          <h5>1. Collecte passive de données personnelles</h5>
          <p>
            Certaines des données personnelles enregistrées sur notre serveur
            sont celles que vous fournissez volontairement lorsque vous
            remplissez des formulaires en ligne et les autres sont recueillies
            automatiquement.
            <br />
            Pendant votre visite, certaines données sont recueillies
            automatiquement :
          </p>
          <ul className="style-inside">
            <li>
              votre adresse IP : une adresse IP est affectée à votre ordinateur
              par votre fournisseur d'accès Internet pour vous permettre
              d'accéder à Internet. Cette adresse est généralement considérée
              comme une information non personnellement identifiable car elle
              change à chaque fois que vous vous connectez,
            </li>
            <li>
              Votre login lorsque vous naviguez dans la partie « Espace Loisirs
              »
            </li>
            <li>
              La date et l'heure de votre visite et les pages que vous avez
              consultées.
            </li>
            <li>
              Le système d'exploitation de votre ordinateur et le logiciel de
              navigation.
            </li>
          </ul>

          <h5>2. Usage des données collectées</h5>
          <p>
            L'APAS BTP utilisera uniquement les données personnelles aux fins
            qui vous ont été indiquées au moment où vous les avez fournies. Les
            données personnelles que vous aurez fournies ou résultant de votre
            navigation sur le site Internet sont réservées à un usage
            strictement interne à l'APAS-BTP.
          </p>

          <h5>3. Mesures de confidentialité</h5>
          <p>
            L'APAS-BTP a mis en place des ressources dédiées permettant de
            stocker de manière sûre les informations personnelles afin d'éviter
            des pertes, altérations ou piratages. Les systèmes de stockage ne
            sont accessibles qu'à un nombre restreint de personnes autorisées.
          </p>

          <h5>4. Accès aux données personnelles</h5>
          <p>
            Conformément à la loi 78-17 du 6 janvier 1978 « relative à
            l'informatique, aux fichiers et aux libertés », sur simple demande
            de votre part l'APAS-BTP supprimera ou modifiera les données
            personnelles que vous lui avez fournies.
          </p>

          <p>
            Si vous souhaitez accéder à vos données personnelles, les faire
            modifier, les faire rectifier ou en demander la suppression,
            veuillez adresser votre demande par courrier Délégué à la protection
            des données - 14-18 rue de la Vanne - CS 40064 - 92541 Montrouge
            CEDEX ou un mail à{" "}
            <a
              className="email-wrapper"
              href="mailto:contactdonneespersonnelles@apas.asso.fr"
            >
              <b>contactdonneespersonnelles@apas.asso.fr</b>
            </a>
            . Pour en savoir plus, consultez notre{" "}
            <Link to="/charte-adherent">
              <b>politique de protection des données</b>
            </Link>
            .
          </p>

          <h3>LIENS EXTERNES</h3>
          <p>
            Le site peut contenir des liens hypertextes pointant vers d'autres
            sites. L'APAS-BTP ne saurait en aucune façon être responsable du
            contenu, du fonctionnement et de l'accès à ces sites.
          </p>

          <h3>PROPRIÉTÉ INTELLECTUELLE</h3>
          <p>
            Ce site est édité par l'APAS-BTP, titulaire de l'ensemble des droits
            de propriété intellectuelle s'y rapportant. Toute reproduction ou
            représentation totale ou partielle de ce site par quelque procédé
            que ce soit, sans l'autorisation expresse de l'APAS-BTP est
            interdite et constituerait une contrefaçon sanctionnée par les
            articles L 335-2 et suivants du Code de la propriété intellectuelle.
            L'APAS-BTP est propriétaire du nom du domaine : apas.asso.fr et de
            la marque APAS, numéro national INPI : 01 3 105 439, dépôt du 14
            juin 2001.
          </p>
        </>
        <small style={{ float: "right" }}>
          <b>Mise à jour :</b> Janvier 2020
        </small>
      </div>
    </div>
  );
}

export default LegalMentionsPage;
