import React from "react";
import {
  ArrowUpOutlined,
  UserOutlined,
  AccountBookOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Card, Statistic } from "antd";

function Tile(props) {
  const { loading, title, value, prefix, onClick } = props;

  const prefixes = {
    arrowUp: <ArrowUpOutlined />,
    user: <UserOutlined />,
    declaration: <FileTextOutlined />,
    bankTransfer: <AccountBookOutlined />,
  };
  return (
    <Card
      className="tile-admin"
      bordered={true}
      hoverable={true}
      loading={loading}
      size="default"
      onClick={onClick}
    >
      <Statistic
        title={title}
        value={value}
        precision={0}
        valueStyle={{
          color: "#ee463b",
        }}
        prefix={prefixes[prefix]}
      />
    </Card>
  );
}
export default Tile;
