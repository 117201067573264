import React from "react";

function TermsServicePage() {
  return (
    <div className="terms-service">
      <h2>CHARTE DE L'ADHÉRENT</h2>
      <div className="content-part">
        <h3>Préambule </h3>
        <p>
          La présente charte de l'adhérent est établie dans le cadre des
          dispositions conventionnelles, ou contractuelles pour les adhérents
          statutaires de l'association.{" "}
        </p>
        <>
          <h3>Article 1 : Statut d'adhérent </h3>
          <h5>Article 1.1 : Généralités </h5>
          <p>
            L'adhérent est l'employeur ou l'entreprise exerçant dans la région
            Ile-de-France les activités définies ci-dessous :
          </p>
          <ul className="style-inside">
            <li>Entreprises du Bâtiment</li>
            <li>Entreprises des Travaux Publics</li>
            <li>Entreprises annexes ou connexes à la Profession</li>
            <li>Organismes professionnels du BTP.</li>
          </ul>
          <p>
            Le ressort territorial de l'association est déterminé par les
            accords collectifs ou conventions collectives (région Ile de France
            hors département de Seine et Marne 77 pour le secteur du bâtiment -
            région Ile de France pour le secteur des travaux publics).
          </p>
          <div>
            <p>Le statut d'adhérent se perd :</p>
            <ul className="style-square">
              <li>
                par la démission : celle-ci doit être signifiée par lettre
                recommandée avec AR adressée au Président du Conseil
                d'Administration de l'association. Cette démission doit être
                acceptée par le Bureau et ne dispense en aucun cas du paiement
                de la cotisation telle qu'elle résulte des accords collectifs ou
                conventions collectives. L'adhérent volontaire à l'association
                qui manifeste sa volonté de démissionner sera tenu à un préavis
                d'un an durant lequel il devra s'acquitter de ses cotisations.
                Le bénéfice des prestations sera maintenu aux salariés pendant
                ce délai.{" "}
              </li>
              <li>
                par la radiation pour inobservation des statuts ou des règles de
                fonctionnement ou tout autre motif grave. Cette décision est
                prise par le Conseil d‘Administration, l'adhérent devant
                obligatoirement avoir été invité par tous moyens au préalable à
                fournir ses explications ou éléments de défense.
              </li>
            </ul>
            <p>
              Les adhérents qui perdraient pour une cause quelconque cette
              qualité resteront tenus au paiement des cotisations telles
              qu'elles résultent des accords collectifs ou conventions
              collectives. De ce fait, les bénéficiaires conserveront le droit
              aux prestations normales de l'association.
            </p>
          </div>
          <h5>Article 1.2 : Adhésion volontaire</h5>
          <p>
            Toute entreprise relevant de la profession du BTP et non située dans
            le ressort territorial indiqué à l'article 1.1 peut adhérer
            volontairement à l'association en remplissant un bulletin
            spécifiquement prévu pour cette catégorie d'adhérent.
          </p>
          <p>
            Indépendamment des cas mentionnés à l'article 1.1, la perte du
            statut d'adhérent pour les entreprises adhérant volontairement à
            l'association peut résulter d'une volonté de démissionner. Dans ce
            cas précis, l'adhérent sera tenu à un préavis d'un an durant lequel
            il devra s'acquitter de la cotisation ; le bénéfice des prestations
            étant maintenu aux salariés pendant ce délai.
          </p>
        </>
        <>
          <h3>Article 2 : Obligation de l'adhérent </h3>
          <p>
            L'adhérent effectue au plus tard le 15 de chaque mois M une
            déclaration des salaires bruts versés par catégories
            professionnelles (ouvrier - Etam - cadre) au titre du mois précédent
            M- 1 en joignant une déclaration nominative des salariés (copie de
            la DSN ou à défaut livre de paie détaillé) comprenant les
            renseignements suivants : nom, prénoms, date et lieu de naissance,
            collège (cadre, Etam, ouvrier), salaire brut mensuel.
          </p>
          <p>
            Par salaire, il convient de comprendre tout ce qui constitue la
            rémunération du travailleur : traitement fixe, indemnités diverses
            en argent ou en nature … et plus généralement tout ce qui est acquis
            par le travailleur en contrepartie ou à l'occasion du travail à
            l'exclusion uniquement de ce qui constitue un remboursement de
            dépenses.
          </p>
          <p>
            L'adhérent ne pourra refuser à l'APAS-BTP la possibilité de
            contrôler l'exactitude des déclarations sur la base desquelles les
            droits d'adhésion, cotisations et redevances des actions de
            prévention ont été calculés. A cet effet, en cas de doute,
            l'Association sera en mesure de demander à l'adhérent des éléments
            justificatifs et, en fonction, valider tout ou partie des
            informations déclarées.
          </p>
          <p>
            L'adhérent est seul responsable de l'exactitude des informations
            susvisées.
          </p>
        </>
        <>
          <h3>Article 3 : Cotisations</h3>
          <h5>Article 3.1 : Calcul des cotisations - Taux applicables</h5>
          <p>
            Sur la base des éléments déclarés, les cotisations sont calculées
            automatiquement et le montant est soumis à validation par
            l'adhérent.
          </p>
          <p>
            Le taux de cotisation est actuellement fixé à 0,4% de la masse
            salariale déclarée tant pour la partie conventionnelle que
            contractuelle (adhésion volontaire).
          </p>
          <p>
            L'association assure le recouvrement des cotisations ainsi validées.{" "}
          </p>
          <h5>Article 3.2 : Paiement des cotisations</h5>
          <p>
            L'adhérent s'acquitte mensuellement de ses cotisations du mois M-1
            au plus tard le dernier jour du mois M soit par virement, soit par
            paiement dématérialisé en CB.
          </p>
          <h5>Article 3.3 : Imputation des paiements</h5>
          <p>
            Tout règlement est imputé sur les périodes mensuelles les plus
            anciennes et en priorité sur les cotisations et les majorations sauf
            imputation spécifiée par l'adhérent.
          </p>
        </>
        <>
          <h3>Article 4 : Défaut de paiement des cotisations</h3>
          <h5>Article 4.1 : Majorations de retard</h5>
          <p>
            Tout défaut dans le paiement des cotisations à l'échéance expose
            l'adhérent défaillant au paiement d'une majoration initiale de 5%,
            calculée sur la base du montant restant dû par l'adhérent.
          </p>
          <p>
            Sauf le cas prévu à l'article 4.2 ci-dessous, cette majoration est
            due même en l'absence de mise en demeure, par dérogation à l'article
            1231-5 alinéa 5 du Code civil.
          </p>
          <h5>Article 4.2 : Recouvrement / Régularisation</h5>
          <p>
            Si l'adhérent défaillant n'a pas régularisé sa situation dans un
            délai de 30 jours calendaires suivant la date d'exigibilité du
            paiement de ses cotisations, l'adhérent sera mis en demeure d'avoir
            à régulariser le paiement de ses cotisations dans les délais les
            plus brefs, faute de quoi le paiement des cotisations et des
            majorations sera poursuivi par toutes voies de droit appropriées.
            Dans ce cas, tous les frais de recouvrement et d'exécution entrepris
            seront à la charge de l'adhérent défaillant, dans la limite de la
            somme forfaitaire de 40€.
          </p>
          <p>
            En outre, il sera appliqué des « majorations de retard
            complémentaires », dont le taux est fixé à 0,20% par mois de retard
            dès l'exigibilité de la cotisation.
          </p>
        </>
        <>
          <h3>Article 5 : Remises</h3>
          <h5>Article 5.1 : Remise automatique</h5>
          <p>
            Les majorations prévues aux articles 4.1 et 4.2 de la présente
            charte donne lieu à une remise automatique sous réserve que
            l'adhérent n'ait pas manqué à ses obligations déclaratives et de
            paiement dans les douze mois précédant la défaillance et qu'il ait
            régularisé le paiement de ses dettes de cotisations dans les trente
            jours de la date d'exigibilité de la déclaration et du paiement.
          </p>
          <p>
            L'APAS-BTP procèdera alors automatiquement à la régularisation du
            compte de l'adhérent dans le délai d'un mois à compte de la date de
            régularisation de la situation et en informera l'adhérent.
          </p>
          <h5>Article 5.2 : Remise gracieuse</h5>
          <p>
            Les majorations prévues aux articles 4.1 et/ou 4.2 de la présente
            charte peuvent également donner lieu à une remise gracieuse lorsque
            l'adhérent défaillant ne satisfait pas aux conditions définies à
            l'article 5.1.
          </p>
          <p>
            La demande de remise gracieuse doit alors être adressée par
            l'adhérent par courrier à l'attention du service « adhésion » de
            l'APAS-BTP et doit être motivée. Aucune demande de remise gracieuse
            ne sera examinée par le service « adhésion » de l'APAS-BTP si à la
            date de sa demande, l'adhérent défaillant n'a pas régularisé le
            paiement de ses dettes de cotisations dues à titre principal.
          </p>
        </>
        <>
          <h3>Article 6 - Protection et conservation des données échangées</h3>
          <p>
            Les DCP des Adhérents ou des bénéficiaires sont hébergés par des
            partenaires liés à l'APAS BTP par un contrat de sous-traitance ou un
            contrat de prestation spécifique.
          </p>
          <p>
            Ces contrats prévoient les mesures technico-organisationnelles de
            nature à protéger les DCP de toute fuite ou violation de données.
          </p>
          <p>
            L'APAS BTP ne conserve les DCP des Adhérents ou des bénéficiaires
            que le temps strictement nécessaire à la réalisation de la finalité
            pour laquelle elles ont été collectées. A l'issue de cette période,
            elles sont soit transférées en base d'archivage intermédiaire soit
            supprimées.
          </p>
        </>
        <>
          <h3>
            Article 7 - Protection des données à caractère personnel des
            salariés de l'adhérent
          </h3>
          <h5>7.1 : Définitions</h5>
          <p>
            « Données à caractère personnel » : toute donnée permettant
            d'identifier directement ou indirectement une personne physique,
            ci-après « DCP ».
          </p>
          <p>
            « Traitement de données » : opérations réalisées sur des données à
            caractère personnel, notamment la collecte, l'enregistrement,
            l'utilisation, la transmission ou la communication.
          </p>
          <p>
            « Responsable de traitement » : entité juridique en charge de
            déterminer les finalités du traitement et les moyens mis en oeuvre
            pour ce traitement.
          </p>
          <p>
            « Sous-traitant » : entité juridique qui traite des données à
            caractère personnel pour le compte du responsable de traitement.
          </p>
          <p>
            « Bénéficiaire » : salarié de l'Adhérent, potentiellement éligible
            ou éligible après vérification de son éligibilité, aux oeuvres
            sociales de l'APAS BTP.
          </p>
          <p>
            Conformément au Règlement européen 2016/679 du 26 avril 2016 sur la
            protection des données à caractère personnel («RGPD») et à la loi «
            Informatique et Libertés » du 6 janvier 1978 modifiée, l'APAS-BTP
            s'engage à mettre en oeuvre des mesures adéquates pour assurer que
            le traitement des données personnelles collectées soit effectué dans
            le respect de la règlementation applicable.
          </p>
          <p>
            En application du principe de transparence, les modalités de ces
            traitements sont précisées dans la politique de protection des
            données de l'APAS BTP, disponible sur le site «apas.asso.fr», dans
            les agences de l'APAS BTP ou dans les centres de santé gérés par
            l'APAS BTP. L'Adhérent devra se reporter au site pour s'assurer que
            la version de la Charte de l'Adhérent en sa possession est la
            dernière version à jour.
          </p>
          <p>
            Les DCP de plusieurs catégories de personnes concernées sont
            recueillies. Il s'agit d'une part de certaines DCP des personnes de
            contact de l'APAS BTP chez les Adhérents pour l'exécution de la
            mission confiées à l'APAS BTP par les Adhérents et d'autre part de
            différentes DCP relatives aux salariés des Adhérents,
            potentiellement Bénéficiaires de ses prestations (collectées
            indirectement par transmission des Adhérents).
          </p>
          <p>
            Les typologies et modalités de traitement des DCP relatives aux
            personnes concernées mentionnées ci-dessus sont décrites dans cet
            article.
          </p>
          <h5>
            7.2 : DCP des personnes de contact de l'APAS BTP chez les Adhérents
            (par collecte directe)
          </h5>
          <ul className="style-inside">
            <li>
              information sur le destinataire de ces DCP et responsable de
              traitement : APAS BTP, 14 rue de la Vanne, 92100 Montrouge ;
            </li>
            <li>
              base légale du traitement : exécution des conventions / contrats
              liant les Adhérents à l'APAS BTP pour l'exercice de sa mission de
              gestion des oeuvres sociales en faveur des salariés des Adhérents
              ;
            </li>
            <li>
              finalité principale de ce traitement : mise à disposition des
              services de l'APAS BTP à ses bénéficiaires ;
            </li>
            <li>
              finalité complémentaire : collecte des DCP des Adhérents pour
              gérer leur adhésion ;
            </li>
            <li>
              liste des DCP traitées par l'APAS BTP : civilité, nom, prénom,
              adresse postale professionnelle, adresse mail professionnelle,
              numéro de téléphone professionnel, fonction ;
            </li>
            <li>
              durée de conservation de ces DCP : cette durée est liée à la durée
              de l'adhésion de l'Adhérent à la Charte de l'Adhérent outre un
              délai de traitement administratif pour apurer les démarches
              administratives de résiliation de cette adhésion ;
            </li>
            <li>
              modalités d'exercice des droits des personnes concernées sur leurs
              DCP.
            </li>
          </ul>
          <p>
            La base légale de ce traitement étant la signature de la Charte de
            l'Adhérent et l'adhésion à l'APAS BTP, l'exercice de ces droits est
            lié à la qualité d'Adhérent et à la démarche d'adhésion.
          </p>
          <ul className="style-inside">
            <li>
              <b>Le droit d'accès : </b>les personnes concernées ont le droit
              d'obtenir (i) la confirmation que leurs DCP sont ou ne sont pas
              traitées et, lorsqu'elles le sont, d'obtenir (ii) l'accès auxdites
              DCP.
            </li>
            <li>
              <b>Le droit de rectification : </b>les personnes concernées ont le
              droit d'obtenir la rectification de leurs DCP qui seraient
              inexactes ainsi que le droit d'obtenir qu'elles soient complétées.
            </li>
            <li>
              <b>Le droit à l'effacement : </b>ce droit peut être exercé par les
              personnes concernées dans certains cas lorsqu'il ne fait pas
              obstacle à la poursuite de la convention liant l'APAS -BTP et
              l'Adhérent. Par ailleurs, des impératifs d'origine légale ou
              fiscale peuvent imposer de conserver lesdites données.
            </li>
            <li>
              <b>Le droit à la limitation du traitement : </b>Dans certains cas,
              vous avez le droit d'obtenir la limitation du traitement de vos
              DCP.
            </li>
            <li>
              <b>Le droit à la portabilité : </b>les personnes concernées ont le
              droit de récupérer leurs DCP pour transmission, le cas échéant, à
              un autre responsable de traitement.
            </li>
            <li>
              <b>
                Le droit de transmettre des instructions concernant
                l'utilisation des données après le décès :{" "}
              </b>{" "}
              Les personnes concernées ont le droit de donner à l'APAS-BTP des
              directives concernant l'utilisation de leurs DCP après leur décès.
            </li>
            <li>
              <b>Le droit d'opposition à traitement </b>ne peut pas être exercé.
              Lorsque les Adhérents s'engagent vis-à-vis de l'APAS-BTP par la
              signature de la Charte de l'Adhérent, le traitement des DCP des
              personnes de contact de l'APAS-BTP au sein de l'Adhérent est
              objectivement nécessaire à l'exécution des dispositions
              conventionnelles ou contractuelles liant les Adhérents et
              l'APAS-BTP.
            </li>
          </ul>
          <p>
            Pour exercer un ou plusieurs de ces droits il convient de contacter
            APAS-BTP à l'adresse suivante : Service Adhésions - 14-18 rue de la
            Vanne - CS 40064 - 92541 Montrouge CEDEX ou un mail à{" "}
            <a
              href="mailto:contactdonneespersonnelles@apas.asso.fr"
              class="email-wrapper"
            >
              <b>contactdonneespersonnelles@apas.asso.fr</b>
            </a>
            .
          </p>
          <p>
            Toute personne concernée qui constate un dysfonctionnement relatif à
            l'exercice de ses DCP non réparé par l'APAS BTP après une première
            réclamation/demandé, a le droit de contacter la CNIL afin
            d'introduire une réclamation.
          </p>
          <h5>
            7.3 : DCP des Bénéficiaires des oeuvres sociales de l'APAS BTP
            (collecte indirecte par transmission des Adhérents)
          </h5>
          <p>
            Pour la réalisation de sa mission de gestion des oeuvres sociales
            des entreprises du BTP prévue par ses statuts fondateurs de 1946
            modifiés le 11 décembre 2020 (article 2), l'APAS BTP doit collecter
            de manière indirecte auprès de ses Adhérents les DCP de leurs
            salariés pour s'assurer de leur qualité de Bénéficiaires de ses
            différentes prestations (santé, social, loisirs vacances et prêt) et
            pour leur fournir lesdites prestations.
          </p>
          <h5>7.3.1. : Information des Bénéficiaires</h5>
          <p>
            L'APAS-BTP, en tant que responsable de traitement, veille à
            collecter et à traiter des données à caractère personnel
            pertinentes, adéquates, non excessives et strictement nécessaires à
            l'atteinte des finalités qui ont été préalablement déterminées.
          </p>
          <p>
            Cette collecte indirecte impose à l'APAS BTP d'informer les
            Bénéficiaires des traitements considérés et de leurs effets sur
            leurs DCP. Les Adhérents s'engagent à informer leurs salariés, par
            tout moyen approprié, de la collecte indirecte de leurs DCP par
            l'APAS BTP, selon les conditions et modalités définies ci-dessous.
            L'attention des Adhérents est attirée sur le fait que cette
            information est une obligation légale de l'APAS BTP au titre du RGPD
            (article 14).
          </p>
          <p>
            Etant cependant sans contact direct avec ses Bénéficiaires
            potentiels avant l'obtention de leur CUA (Code Unique APAS), l'APAS
            BTP ne peut s'adresser aux Bénéficiaires que par l'entremise des
            Adhérents, seuls détenteurs des DCP permettant de définir
            l'éligibilité de leurs salariés au statut de Bénéficiaires.
          </p>
          <p>
            Cette communication doit être réalisée par l'Adhérent auprès de ses
            Bénéficiaires préalablement à toute transmission à l'APAS BTP et
            comprendre à minima les informations suivantes :
          </p>
          <ul className="style-inside">
            <li>
              information sur le destinataire de leurs données et responsable de
              traitement : APAS-BTP, 14 rue de la Vanne, 92100 Montrouge ;
            </li>
            <li>
              finalité principale de ce traitement : mise à disposition des
              services de l'APAS-BTP à ses bénéficiaires ;
            </li>
            <li>
              finalité complémentaire : transmission par les Adhérents des DCP
              de leurs salariés pour leur permettre de bénéficier des oeuvres
              sociales de l'APAS-BTP ;
            </li>
            <li>
              liste des données personnelles transférées à l'APAS-BTP :
              civilité, nom, prénoms, date de naissance, lieu de naissance, CSP,
              salaire brut ;
            </li>
            <li>
              durée de conservation de ces données personnelles : cette durée
              est liée au caractère de Bénéficiaire éligible aux oeuvres
              sociales de l'APAS-BTP du salarié de l'Adhérent ou d'une autre
              société adhérente s'il vient à changer d'employeur :
            </li>
            <li>
              modalités d'exercice des droits des Bénéficiaires sur leurs
              données personnelles transmises à l'APAS-BTP.
            </li>
          </ul>
          <p>
            Cette transmission étant fondée sur la base légale de l'intérêt
            légitime, aucune transmission par l'Adhérent des DCP d'un
            bénéficiaire qui s'y opposerait ne peut être faite.
          </p>
          <p>
            L'APAS- BTP fournira à ses Adhérents qui le sollicitent, un modèle
            de communication à leurs salariés. Il leur appartiendra de vérifier
            les éventuels ajustements de ce modèle à leur règlementation
            spécifique.
          </p>
          <h5>7.3.2. : Base légale et finalités du traitement</h5>
          <p>
            La base légale du traitement des DCP des Bénéficiaires est fondée
            sur l'intérêt légitime de l'APAS-BTP.
          </p>
          <p>Finalités principales de ce traitement :</p>
          <ul className="style-inside">
            <li>
              mise à disposition des services de l'APAS BTP à ses bénéficiaires
              ;
            </li>
            <li>contrôle des droits des Bénéficiaires et des ayants-droits.</li>
          </ul>
          <p>
            Finalité complémentaire de ce traitement : Transmission par les
            Adhérents des DCP de leurs salariés pour leur permettre de
            bénéficier des oeuvres sociales de l'APAS BTP.
          </p>
          <h5>7.3.3. : Communication à des tiers</h5>
          <p>
            Une fois le Bénéficiaire identifié comme tel, l'APAS- BTP, dans
            l'exercice de sa mission de gestion des oeuvres sociales, pourra
            transférer à des tiers les DCP collectées aux fins de poursuivre les
            finalités définies et toujours dans le respect de la règlementation
            applicable.
          </p>
          <p>
            Ainsi, l'APAS-BTP peut donner accès aux DCP des bénéficiaires
            notamment :
          </p>
          <ul className="style-inside">
            <li>aux services sociaux de la CRAMIF ;</li>
            <li>aux services sociaux de la santé au travail ;</li>
            <li>
              à toute autorité judiciaire ou administrative qui en ferait la
              demande ou la réquisition auprès de l'APAS BTP ;
            </li>
            <li>aux prestataires de services tiers :</li>
            <ul className="style-square">
              <li>
                pour la gestion du site web de l'APAS BTP (par exemple avec des
                prestataires de gestion de site internet, prestataire
                d'infogérance et de maintenance informatique, fournisseur de
                logiciel CRM, prestataire d'hébergement, prestataire de gestion
                des serveurs, etc…) ;
              </li>
              <li>
                pour sa gestion administrative, comptable et financière (par
                exemple avec des fournisseurs de logiciels financiers, des
                prestataires d'aide à la gestion d'entreprise, de stockage et de
                traitement de données, prestataire de marketing / communication,
                prestataire de livraison / gestion des courriers, etc…) ;
              </li>
              <li>
                pour la gestion technique, administrative et financière des
                oeuvres sociales proposées par l'APAS BTP à ses Bénéficiaires
                (par exemple pour le Pôle Santé avec des fournisseurs de
                logiciels de gestion des données de santé, des professionnels de
                santé
              </li>
              <li>
                pour le Pôle Loisirs avec des prestataires de transport
                prestataire de tourisme, fournisseurs de logiciels de tourisme
                et d'organisation de voyages, partenaires des services de
                billetterie, agences de voyage, services de réservation, etc…) ;
              </li>
            </ul>
            <li>
              aux sociétés ou administrations partenaires de l'APAS-BTP: banque,
              assurance, huissiers, avocats, cabinets et organismes de crédit,
              ANTAI, Maison Départementale des Personnes Handicapées, etc…
            </li>
            <li>
              pour se conformer aux obligations légales et administratives,
              notamment envers l'Administration fiscale, les caisses de
              retraite, organismes de prévoyance, sécurité sociale et mutuelle,
            </li>
            <li>aux entreprises adhérentes dans certains cas.</li>
          </ul>
          <p>
            Tout transfert de DCP est dûment encadré par un contrat qui vise les
            cas d'utilisation des DCP transmises, de traitement, de modalités de
            conservation et de sécurité.
          </p>
          <p>
            Par ailleurs, les données à caractère personnel des bénéficiaires
            peuvent être partagées avec des tiers pour les motifs suivants :
          </p>
          <ul className="style-inside">
            <li>
              en réponse à une procédure judiciaire ou administrative de toute
              nature ou à des mesures d'application de la loi réclamées par les
              autorités compétentes ; Version 2 - 13/05/2024
            </li>
            <li>
              pour se conformer à des obligations légales, pour protéger les
              droits et/ou la sûreté d'un individu, pour protéger les droits et
              la propriété de l'APAS-BTP et d'empêcher les problèmes de fraude,
              de sécurité ou techniques.
            </li>
          </ul>
          <h5>7.3.4. : Transferts en dehors de l'Union Européenne</h5>
          <p>
            Les destinataires listés ci-dessus à qui l'APAS-BTP est susceptible
            de communiquer les données à caractère personnel des salariés de
            l'Adhérent pourraient être domiciliés à l'étranger, en dehors de
            l'Espace Économique Européen ou dans les pays suivants : États Unis
            d'Amérique.
          </p>
          <p>
            Aucun transfert de DCP en dehors de l'Espace Économique Européen ne
            pourra être fait sans être encadré par un contrat prévoyant des
            dispositions spécifiques pour assurer, conformément à la
            règlementation en vigueur au moment du transfert, une protection
            adéquate de ces DCP.
          </p>
          <h5>7.3.5. : Liens vers des sites tiers</h5>
          <p>
            Le Bénéficiaire peut dans certains cas, utiliser le code communiqué
            par l'APAS-BTP pour accéder directement à des prestations fournies
            par des partenaires externes sans lien contractuel formalisé avec
            l'APAS-BTP.
          </p>
          <p>
            L'APAS-BTP ne traite pas à cette occasion les DCP de ses
            Bénéficiaires qui sont transmises directement par ces derniers à ces
            partenaires externes.
          </p>
          <p>
            L'APAS-BTP invite le Bénéficiaire à consulter la politique de
            protection des données de ces partenaires externes et de les
            contacter directement pour tout exercice de droits.
          </p>
          <h5>
            7.3.6. : Durée de conservation des DCP collectées indirectement
          </h5>
          <p>
            Les DCP traitées par l'APAS-BTP sont conservées le temps nécessaire
            à l'accomplissement de la finalité pour laquelle elles ont été
            collectées.
          </p>
          <p>
            Les durées de conservation pratiquées par l'APAS-BTP respectent la
            réglementation applicable en vigueur, à savoir :
          </p>
          <ul className="style-inside">
            <li>
              Les DCP relatives au compte du Bénéficiaire sur le site web seront
              conservées tant que ledit compte reste ouvert et actif.
            </li>
            <li>
              Les DCP collectées lorsque le Bénéficiaire souhaite bénéficier des
              conseils et de l'accompagnement de l'APAS-BTP seront conservées le
              temps nécessaire au traitement de la demande et jusqu'à maximum 2
              ans suivant l'expiration des conseils et démarches
              (administratives ou autres) requises par le Bénéficiaire ; ces
              données pourront toutefois dans certains cas être archivées en
              base intermédiaire pour une durée plus longue en conformité avec
              les obligations légales de l'APAS-BTP et les délais de
              prescription applicables.
            </li>
            <li>
              Les DCP collectées dans le cadre des offres de prêt sont
              conservées, pour les prêts accordés, pour toute la durée du prêt
              puis pour une durée de 5 ans après le terme du remboursement. Les
              DCP relatives aux prêts refusés seront conservées jusqu'à 6 mois
              après la décision de refus (elles pourront cependant dans certains
              cas être archivées pour une durée plus longue, en conformité avec
              les obligations légales de l'APAS-BTP et les délais de
              prescription applicables).
            </li>
            <li>
              Les DCP collectées dans le cadre des services de soutien scolaire,
              réservation de vacances, réservation de billets loisirs et
              organisation d'évènements APAS-BTP (incluant vos données de
              paiement) seront conservées jusqu'à la fin du contrat relatif au
              service souscrit (c'està- dire jusqu'à ce que le séjour,
              l'évènement ou l'accompagnement soit terminé) puis seront
              archivées en conformité avec les obligations légales de l'APAS-BTP
              et les délais de prescription applicables, pouvant aller jusqu'à
              10 ans pour les données nécessaires en matière comptable).
            </li>
            <li>
              Les données médicales sont conservées en application des
              obligations légales applicables à savoir notamment 20 ans, à
              compter de la dernière consultation, pour le dossier médical et 3
              ans pour le décompte de règlement de la sécurité sociale. Les
              données collectées uniquement à des fins de gestion administrative
              (prise de rendez-vous notamment) sont conservées pendant 2 ans.
            </li>
            <li>
              Les dossiers de candidats sont conservés 6 mois à compter du
              dernier contact avec lesdits candidats sauf si le salarié s'oppose
              à une telle durée de conservation et souhaite que ses données
              soient supprimées une fois l'examen de sa candidature terminé.
              Au-delà les données sont archivées en conformité avec les délais
              de prescription applicables (généralement 6 ans) ;
            </li>
            <li>
              Les données collectées dans le cadre des formulaires de contact de
              l'APAS-BTP sont conservées pour la durée nécessaire au traitement
              des demandes/requêtes du Bénéficiaire.
            </li>
            <li>
              Lorsque des cookies ou autre traceur sont déposés sur le terminal
              du salarié de l'adhérent, ils sont conservés en conformité avec
              les obligations légales et recommandations (c'est-àdire 13 mois
              pour les cookies et traceurs non-essentiels au fonctionnement du
              Site Web).
            </li>
          </ul>
          <h5>7.3.7. : Les droits du Bénéficiaire sur ses DCP</h5>
          <p>Le Bénéficiaire dispose des droits suivants sur ses DCP :</p>
          <ul className="style-inside">
            <li>
              <b> Le droit d'accès : </b>les personnes concernées ont le droit
              d'obtenir (i) la confirmation que leurs DCP sont ou ne sont pas
              traitées et, lorsqu'elles le sont, d'obtenir (ii) l'accès auxdites
              DCP.
            </li>
            <li>
              <b>Le droit de rectification : </b>les personnes concernées ont le
              droit d'obtenir la rectification de leurs DCP qui seraient
              inexactes ainsi que le droit d'obtenir qu'elles soient complétées.
            </li>
            <li>
              <b>Le droit à l'effacement : </b>ce droit peut être exercé par les
              personnes concernées dans certains cas lorsqu'il ne fait pas
              obstacle à la poursuite de la convention liant l'APAS -BTP et
              l'Adhérent. Par ailleurs, des impératifs d'origine légale ou
              fiscale peuvent imposer de conserver lesdites données.
            </li>
            <li>
              <b>Le droit à la limitation du traitement : </b>Dans certains cas,
              vous avez le droit d'obtenir la limitation du traitement de vos
              DCP.
            </li>

            <li>
              <b>Le droit à la portabilité : </b>les personnes concernées ont le
              droit de récupérer leurs DCP pour transmission, le cas échéant, à
              un autre responsable de traitement.
            </li>
            <li>
              Le <b>droit d'opposition </b>à traitement. Pour bénéficier des
              oeuvres sociales de l'APAS BTP, il est indispensable d'être
              identifié en tant que tel après vérification de son éligibilité.
              L'opposition à traitement à certaines DCP peut entraîner
              l'impossibilité de l'accès aux oeuvres sociales puisque ces
              traitements sont indispensables à l'accès aux oeuvres sociales.
              Cependant, certaines prestations de l'APAS-BTP peuvent nécessiter
              la collecte de DCP spécifiques, en plus des DCP collectées aux
              fins d'éligibilité aux oeuvres sociales. L'opposition à traitement
              de ces DCP particulières n'entraînera pas la perte de l'accès aux
              oeuvres sociales.
            </li>
            <li>
              <b>
                Le droit de transmettre des instructions concernant
                l'utilisation des données après le décès
              </b>
              : Les personnes concernées ont le droit de donner à l'APAS-BTP des
              directives concernant l'utilisation de leurs DCP après leur décès.
            </li>
          </ul>
          <p>
            Pour exercer un ou plusieurs de ces droits il convient de contacter
            APAS-BTP à l'adresse suivante : Service Adhésions - 14-18 rue de la
            Vanne - CS 40064 - 92541 Montrouge CEDEX ou un mail à{" "}
            <a
              href="mailto:contactdonneespersonnelles@apas.asso.fr"
              class="email-wrapper"
            >
              <b>contactdonneespersonnelles@apas.asso.fr</b>
            </a>
            .
          </p>
          <p>
            Toute personne concernée qui constate un dysfonctionnement relatif à
            l'exercice de ses DCP non réparé par l'APAS BTP après une première
            réclamation/demande, peut contacter la CNIL afin d'introduire une
            réclamation.
          </p>
          <h5>7.4 : Contact</h5>
          <p>
            Pour toutes questions supplémentaires ou problématiques concernant
            la politique de protection des données, il convient de contacter
            l'APAS-BTP par email à l'adresse suivante :{" "}
            <span class="email-wrapper">
              <a
                href="mailto:contactdonneespersonnelles@apas.asso.fr"
                class="email-wrapper"
              >
                <b>contactdonneespersonnelles@apas.asso.fr</b>
              </a>
              .
            </span>
          </p>
        </>
      </div>
    </div>
  );
}

export default TermsServicePage;
