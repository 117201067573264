import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import React, { useEffect, useState } from "react";
import Tile from "./Tile";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Spin } from "antd";

function AdminDashboardPage() {
  const backendUrl = BACKEND_URL;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [regenPdfLoading, setRegenPdfLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    getFirstDayOfMonthTimestamp();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(`${backendUrl}/api/admin/dashboard/background-data`, {
        withCredentials: true,
        headers: headers,
      })
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((error) => {
        console.error("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFirstDayOfMonthTimestamp = () => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const timestamp = firstDayOfMonth.getTime() / 1000;
    return timestamp;
  };

  const regeneratePdf = () => {
    setRegenPdfLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(`${backendUrl}/api/admin/dashboard/regenerate-pdf`, {
        withCredentials: true,
        headers: headers,
      })
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.error("error", error);
      })
      .finally(() => {
        setRegenPdfLoading(false);
      });
  };

  return (
    <div className="block-right">
      <div className="info-admin">
        <Tile
          title="Nouveaux adhérents"
          loading={isLoading}
          value={data?.nbNewClaimedCompanies}
          prefix="user"
          onClick={() => {
            navigate("/users", {
              state: { claimDate: getFirstDayOfMonthTimestamp() },
            });
          }}
        />
        <Tile
          title="Déclarations remplies"
          loading={isLoading}
          value={data?.nbNewDeclarations}
          prefix="declaration"
          onClick={() => {
            const now = new Date();
            navigate("/tableDeclaration", {
              state: {
                startMonth: now.getMonth() + 1,
                startYear: now.getFullYear(),
              },
            });
          }}
        />
        <Tile
          title="Virements en cours"
          loading={isLoading}
          value={data?.nbOngoingBankTransfer}
          prefix="bankTransfer"
          onClick={() => {
            navigate("/tableDeclaration", { state: { payed: "ongoing" } });
          }}
        />
        <Tile
          title="Total inscrits (toutes périodes)"
          loading={isLoading}
          value={data?.nbTotalClaimedCompanies}
          prefix="user"
          onClick={() => {
            navigate("/users");
          }}
        />
      </div>
      {data?.pdfNotCreated > 0 && (
        <div className="info-admin">
          <Alert
            type="warning"
            message={`Attention, ${data?.pdfNotCreated} facture(s) non générée(s) !`}
            style={{ width: 500 }}
            action={
              <Button
                size="small"
                onClick={regeneratePdf}
                disabled={regenPdfLoading}
                success
              >
                {regenPdfLoading ? <Spin /> : "Régénérer"}
              </Button>
            }
          />
        </div>
      )}
    </div>
  );
}

export default AdminDashboardPage;
