import React from "react";

function IconUser() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          fill="#EE463B"
          fillRule="nonzero"
          d="M24.014 5C13.554 5 5 13.555 5 24s8.555 19 19 19 19-8.555 19-19c.014-10.445-8.541-19-18.986-19zm0 4.828c3.726 0 6.569 2.856 6.569 6.583 0 3.726-2.843 6.569-6.57 6.569-3.726 0-6.568-2.843-6.568-6.57 0-3.726 2.842-6.582 6.569-6.582zm0 27.868c-4.747 0-8.936-2.476-11.398-6.093 0-3.795 7.603-5.89 11.398-5.89 3.808 0 11.397 2.095 11.397 5.89-2.475 3.617-6.65 6.093-11.397 6.093z"
        />
      </svg>
    </>
  );
}

export default IconUser;
