import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function SpinLoading({ width }) {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
            color: "white",
            width: width ? width : "80px",
          }}
          spin
        />
      }
    />
  );
}

export default SpinLoading;
