import { Result } from "antd";
import React from "react";
import { ButtonLink } from "../../../misc/button/Button";

function PendingResultPayment() {
  return (
    <div className="block-right">
      <div className="info-result-payment">
        <Result
          status="warning"
          title="Votre virement est en attente de traitement."
        />
        <div className="button-container">
          <ButtonLink to="/tableDeclaration" children="Retour" />
        </div>
      </div>
    </div>
  );
}

export default PendingResultPayment;
