import React, { useContext } from "react";
import Salary from "./Salary";
import { Alert } from "antd";
import { AuthContext } from "../../context/AuthProviderWrapper";

function CreateDeclaration() {
  const { user, activeCompany } = useContext(AuthContext);

  const infoAlertContent = (
    <div>
      <p>
        <b> Cotisation adhérent conventionnel : </b>
        Dès lors que la cotisation est rendue obligatoire pour une catégorie
        professionnelle déterminée dans le cadre d'une convention régionale, la
        TVA ne s'applique pas. Si l'adhérent décide de cotiser volontairement
        pour la fraction de la catégorie professionnelle non visée par une
        convention régionale, la TVA s'appliquera sur la quote-part de la
        cotisation optionnelle.
        <br /> <br />
        <b>
          Cotisation adhérent volontaire (comptes directs - entreprises du
          Bâtiment du département 77) :{" "}
        </b>
        la TVA s'applique sur l'ensemble de la cotisation
      </p>
    </div>
  );

  return (
    <div className="block-right">
      <Alert
        message={infoAlertContent}
        type="info"
        showIcon
        style={{ alignItems: "center", margin: "auto", width: "100%" }}
      />
      <div className="info-declaration">
        <h2>Declaration de salaires mensuelle</h2>
        <h3>{activeCompany?.companyName}</h3>
        <div>
          <div>Raison sociale : {activeCompany?.companyName}</div>
          <div>Identifiant APAS : {activeCompany?.membershipCode}</div>
        </div>
      </div>
      <Salary isEdit={false} />
    </div>
  );
}

export default CreateDeclaration;
