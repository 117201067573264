import axios from "axios";
import React, { useState, createContext, useEffect } from "react";
import { BACKEND_URL } from "../../config/config";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

function AuthProviderWrapper(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [activeCompany, setActiveCompany] = useState({});
  const [userCompanies, setUserCompanies] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openModalChooseCompany, setOpenModalChooseCompany] = useState(false);

  const backendUrl = BACKEND_URL;
  const navigate = useNavigate();

  useEffect(() => {
    authenticateUser();
  }, []);

  useEffect(() => {
    if (isLoggedIn && userCompanies?.length > 0) {
      handleCompanySelection();
    }
  }, [isLoggedIn, userCompanies]);

  const handleCompanySelection = (isNext = null) => {
    const selectedCompany = isNext ? isNext : user?.lastCompanyUsed;
    if (selectedCompany) {
      setActiveCompany(
        userCompanies.find(
          (company) => company.membershipCode === selectedCompany
        )
      );
      setOpenModalChooseCompany(false);
    } else if (userCompanies?.length === 1) {
      setActiveCompany(userCompanies[0]);
      setOpenModalChooseCompany(false);
    } else if (userCompanies?.length > 1) {
      setOpenModalChooseCompany(true);
    } else {
      setActiveCompany({});
    }
  };

  const authenticateUser = () => {
    axios
      .get(`${backendUrl}/api/login/user_info`, {
        withCredentials: true,
      })
      .then((response) => {
        const user = response.data;
        setIsLoggedIn(true);
        setIsLoading(false);
        setUser(user);
        setUserCompanies(user?.companies);
        if (user?.isAdmin) {
          return setIsAdmin(true);
        }
      })
      .catch(() => {
        setIsLoggedIn(false);
        setIsLoading(false);
        setUser(null);
        setIsAdmin(false);
      });
  };

  /**if use async await**/
  const authenticateUser2 = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/login/user_info`, {
        withCredentials: true,
      });
      const user = response.data;
      setIsLoggedIn(true);
      setIsLoading(false);
      setUser(user);
      setUserCompanies(user?.companies);
      if (user?.isAdmin) {
        setIsAdmin(true);
      }
    } catch (error) {
      setIsLoggedIn(false);
      setIsLoading(false);
      setUser(null);
      setIsAdmin(false);
    }
  };

  const logOutUser = () => {
    axios
      .get(`${backendUrl}/api/login/logout`, {
        withCredentials: true,
      })
      .then(() => {
        setIsLoggedIn(false);
        setUser(null);
        setIsAdmin(false);
        setUserCompanies([]);
        setActiveCompany({});
        navigate("/");
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const changeActiveCompany = (nextActiveCompany) => {
    axios
      .post(
        `${backendUrl}/api/user/active-company`,
        {
          activeCompany: nextActiveCompany.membershipCode,
        },
        {
          withCredentials: true,
        }
      )
      .then(() => {
        handleCompanySelection(nextActiveCompany.membershipCode);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        setIsLoading,
        user,
        setUser,
        authenticateUser,
        logOutUser,
        isAdmin,
        activeCompany,
        changeActiveCompany,
        userCompanies,
        setUserCompanies,
        openModalChooseCompany,
        setOpenModalChooseCompany,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProviderWrapper, AuthContext };
